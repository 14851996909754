import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Spinner
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as LinkDom } from "react-router-dom";
import { DataTable } from "../components/DataTable";
import { SpecialHeading } from "../components/individualTabs/PanelLayout";
import { setupIntercom } from "../helpers/helpers";
import { useGetClientsData } from "../hooks/useGetClientsData";
import { useLogOut } from "../hooks/useLogOut";
import { setActiveClientsData } from "../redux/clients/clientsSlice";
import { clientsSelectors } from "../redux/clients/selectors";
import { individualSelectors } from "../redux/individual/selectors";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { ClientData } from "../types/tables-data";

export const CurrentCases = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const { onSubmitGetClientsData, isLoadingGetClientsData, clientsData } =
    useGetClientsData();

  const dispatch = useDispatch();
  const activeClients = useSelector(clientsSelectors.selectAll);

  const lawyerEmail = useSelector(lawyerSelectors.selectEmail);
  const individualEmail = useSelector(individualSelectors.selectEmail);

  const lawyerUid = useSelector(lawyerSelectors.selectUid);
  const individualUid = useSelector(individualSelectors.selectUid);

  const { resetIndivReduxStore } = useLogOut();

  const getClientsData = async () => {
    console.log("current cases getClientData");
    try {
      await onSubmitGetClientsData(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    resetIndivReduxStore();
  }, []);

  useEffect(() => {
    getClientsData();
    setupIntercom(lawyerEmail || individualEmail, lawyerUid || individualUid);
  }, []);

  useEffect(() => {
    dispatch(setActiveClientsData(clientsData));
  }, [clientsData]);

  const columnHelper = createColumnHelper<ClientData>();
  const columns = [
    columnHelper.accessor("id", {
      enableHiding: true,
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Name"
    }),
    columnHelper.accessor("birthDay", {
      cell: (info) =>
        info && info.getValue() && info.getValue().toString() !== "NaN"
          ? dayjs(info.getValue()).format("MM/DD/YYYY")
          : "-",
      header: "DoB",
      meta: {
        isNumeric: true
      }
    }),
    columnHelper.accessor("visa", {
      cell: (info) => info.getValue(),
      header: "Visa"
    }),
    columnHelper.accessor("citizenship", {
      cell: (info) => info.getValue() || "-",
      header: "Citizenship"
    }),
    columnHelper.accessor("createdDate", {
      cell: (info) => dayjs(info.getValue()).format("MM/DD/YYYY"),
      header: "Creation Date",
      meta: {
        isNumeric: true
      }
    })

    // columnHelper.accessor("status", {
    //   cell: (info) => `Step ${info.getValue()}`,
    //   header: "Status"
    // })

    // columnHelper.accessor("priority", {
    //   cell: (info) => info.getValue(),
    //   header: "Priority"
    // }),
    // columnHelper.accessor("actionNeeded", {
    //   cell: (info) => (info.getValue() ? "Yes" : "No"),
    //   header: "Action needed"
    // })
  ];

  return (
    <Flex flexDirection="column" flex="1" className="p-4 overflow-scroll">
      <Box width="100%">
        <SpecialHeading title="Current cases" />
      </Box>

      {activeClients.length === 0 && isLoadingGetClientsData && (
        <Spinner
          alignSelf="center"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="primary.blue"
          size="xl"
        />
      )}
      {activeClients.length !== 0 && !isLoadingGetClientsData && (
        <Box className=" overflow-x-scroll overflow-y-scroll max-h-[70vh]">
          <DataTable columns={columns} data={activeClients} isSortable />
        </Box>
      )}
      {activeClients.length === 0 && !isLoadingGetClientsData && (
        <Heading size="sm" my="15px">
          No active cases found. <br /> Click &quot;New case&quot; below to add
          the first client
        </Heading>
      )}

      <LinkDom to="/new-case">
        <Button variant="primaryFilled" my="20px">
          New case
        </Button>
      </LinkDom>
    </Flex>
  );
};
