// PageWrapper.js
import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import HamburgerMenu from "../components/HamburgerMenu";
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar/Sidebar";
import styles from "../styles/dashboard-layout.module.css";

interface PageWrapperProps {
  component: JSX.Element;
  withBackground?: boolean;
}

const PageWrapper = ({
  component,
  withBackground = true
}: PageWrapperProps) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col h-full flex-1 overflow-hidden">
      {isMobile ? <HamburgerMenu /> : <Header />}
      <div className="flex flex-1 overflow-hidden">
        <Sidebar
          toggleSidebar={() =>
            isMobile ? setShowSidebar(!showSidebar) : setShowSidebar(false)
          }
          showSidebar={showSidebar}
        />
        <main className="xl:mx-auto 2xl:w-[1024px] overflow-x-hidden overflow-y-scroll flex-1 px-4 ">
          {component}
        </main>
      </div>
    </div>
  );
};

export default PageWrapper;
