import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { documentSelectors } from "../../../redux/documents/selectors";
import { Item } from "../../../types/document";
import { DataDocs } from "../../../types/tables-data";
import DropdownMulti from "../../common/DropdownMulti";

type Props = {
  onConfirm: () => void;
  selectedExhibits: Record<string | string, Item | null>;
  setExhibits: (exhibits: Record<string | number, Item | null>) => void;
  evidenceDocs: DataDocs[];
  closeDialog: () => void;
};

export const SelectExhibitDialog = ({
  selectedExhibits,
  setExhibits,
  evidenceDocs,
  onConfirm,
  closeDialog
}: Props) => {
  const { isOpen, onOpen } = useDisclosure();
  const evidenceExhibitMap = useSelector(documentSelectors.evidenceExhibitMap);
  const exhibitNumbers = Object.keys(evidenceExhibitMap!);
  const initialExhibitMap: Record<string | string, Item | null> =
    exhibitNumbers.reduce((acc, exhibitNumber) => {
      acc[exhibitNumber] = null; // Setting each exhibit number to `null` as placeholder for empty Item
      return acc;
    }, {} as Record<string | string, Item | null>);

  return (
    <Modal closeOnOverlayClick={false} isOpen onClose={closeDialog}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select evidence to summarize</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            closeDialog();
          }}
        />
        <ModalBody pb={6}>
          <DropdownMulti
            selectAllInitially
            selectedValues={selectedExhibits}
            title="Documents"
            onItemSelect={(values) => {
              setExhibits(values);
            }}
            list={initialExhibitMap}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant="secondaryOutline"
            onClick={() => {
              closeDialog();
            }}
          >
            Cancel
          </Button>
          <Button
          // TODO : FIX
            isDisabled={Object.keys(selectedExhibits).length === 0}
            variant="primaryFilled"
            ml={3}
            onClick={() => {
              onConfirm();
            }}
          >
            Generate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
