import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  SkeletonText,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
  useToast,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { db } from "../../../../api/firebaseApi";
import { SelectField } from "../../../../forms/SelectField";
import { scrollToElementByRef } from "../../../../helpers/dom_helpers";
import {
  downloadFileAsBlob,
  handleUnsupportedExtension
} from "../../../../helpers/file_helpers";
import {
  fetchFiles,
  isValidPhoneNumber,
  sleep
} from "../../../../helpers/helpers";
import { useAddFileToDB } from "../../../../hooks/useAddFileToDB";
import { useDeleteFileFromDB } from "../../../../hooks/useDeleteFileFromDB";
import useDeleteExpertLetter from "../../../../hooks/useDeleteIndivExpertLetter";
import { useExpertLetterDrafts } from "../../../../hooks/useExpertLetterDrafts";
import useFileActions from "../../../../hooks/useFileActions";
import { useGetFilesFromDB } from "../../../../hooks/useGetFilesFromDB";
import { useSubscribeToJob } from "../../../../hooks/useSubscribeToJob";
import { useUpdateIndivFormsData } from "../../../../hooks/useUpdateIndivFormsData";
import { useUserRoleAndSubscription } from "../../../../hooks/useUserRoleAndSubscription";
import { ExpertLetter } from "../../../../redux/clients/clientsSlice";
import { documentSelectors } from "../../../../redux/documents/selectors";
import {
  EXTRACTIONSTATES,
  ExtractionStatus,
  JobType
} from "../../../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../../../redux/extraction-jobs/selectors";
import {
  removeResume,
  setActiveExpertLetter
} from "../../../../redux/individual/individualSlice";
import { individualSelectors } from "../../../../redux/individual/selectors";
import { Item } from "../../../../types/document";
import {
  DATABASE,
  DataDocs,
  visaFromPath,
  VISAVALUE
} from "../../../../types/tables-data";
import { ExplanationForm } from "../../../ExplanationFormBlock";
import { UploadFilesPlaceholder } from "../../../UploadFilesPlaceholder";
import { CustomBox } from "../../../common/CustomBox";
import { CustomButton } from "../../../common/CustomButton";
import { DatePicker } from "../../../common/DatePicker";
import DropdownMulti from "../../../common/DropdownMulti";
import { CustomPhoneInput } from "../../../common/PhoneInput";
import { RichTextEditor } from "../../../common/RichTextEditor";
import { SpecialHeading } from "../../PanelLayout";
import ExpertLetterModal from "../../individualDrafts/expertLetterDrafts/ExpertLetterDraftDialog";
import { ExpertInfoSkeleton } from "./FormSkeleton";
import { DropdownGeneric } from "../../../common/DropdownGeneric";
import { SectionWrapper } from "../../../common/SectionWrapper";

export const AddEditExpertLetterInputs = () => {
  const location = useLocation();
  const { shouldSaveForLater } = location.state || {
    shouldSaveForLater: false
  };
  const { id, expertId, visaType } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uid = id ?? useSelector(individualSelectors.selectUid);
  const firstName = useSelector(individualSelectors.selectFirstName);
  const lastName = useSelector(individualSelectors.selectLastName);
  const activeJobs = useSelector(extractionSelectors.selectActiveJobs);
  const reducedActiveJobs = activeJobs.map((job) => job.id);
  const evidenceDocuments = useSelector(documentSelectors.evidenceDocs);
  const reducedEvidenceMap = evidenceDocuments.reduce<Record<string, Item>>(
    (acc, doc: DataDocs) => {
      if (doc.id && doc.type && doc.criterion) {
        acc[doc.id] = {
          type: doc?.autoTitle ?? "",
          category: doc.criterion
        };
      }
      return acc;
    },
    {}
  );

  const [selectedEvidenceIds, setSelectedEvidenceIds] = useState<string[]>([]);

  const activeExpertLetter = useSelector(
    individualSelectors.selectActiveExpertLetter
  );
  const { isIndividual } = useUserRoleAndSubscription();

  const [expertName, setExpertName] = useState<string>(
    activeExpertLetter?.expertName ?? ""
  );

  const { onSubmitAddUpdateLetter, isLoadingUpdateFormsData } =
    useUpdateIndivFormsData(uid);
  const { onSubmitDeleteResume, resumeDeletionLoading, error } =
    useDeleteExpertLetter(uid);

  const { deleteFolderContents, onSubmitDeleteFile } = useDeleteFileFromDB();

  const buttonCancelRef = useRef<HTMLButtonElement | null>(null);

  const { updateNestedField } = useExpertLetterDrafts(uid);

  const initListSource = activeExpertLetter?.expertiseSourceList || [];
  const initListSpecific = activeExpertLetter?.specificRoleList || [];
  const initListAbility = activeExpertLetter?.abilitiesList || [];
  const initListImportance = activeExpertLetter?.roleImportanceList || [];

  const [specificRoleList, setSpecificRoleList] =
    useState<string[]>(initListSpecific);
  const [abilitiesList, setAbilitiesList] = useState<string[]>(initListAbility);
  const [roleImportanceList, setRoleImportanceList] =
    useState<string[]>(initListImportance);
  const [expertiseSourceList, setExpertiseSourceList] =
    useState<string[]>(initListSource);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [isLoadingExpert, setIsLoadingExpert] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState<FileList | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [resumeUrl, setResumeUrl] = useState<string | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const { onSubmitAddFile, fileRef } = useAddFileToDB();
  const { onSubmitGetFiles, storageFiles } = useGetFilesFromDB();

  const [docId, setDocId] = useState<string | undefined | null>(expertId);
  const [fileName, setFileName] = useState<string | null>(null);
  const highlightsRef = useRef<HTMLDivElement | null>(null);
  const [showHighlightsRequired, setShowHighlightsRequired] =
    useState<boolean>(false);
  const [showDeletionDialog, setShowDeletionDialog] = useState<boolean>(false);
  const resumeUploadRef = useRef<HTMLDivElement>(null);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [lastFilePathSegment, setLastFilePathSegment] = useState("");
  const [isProcessingInputs, setIsProcessingInputs] = useState(false);

  // Draft generation
  const [showGenerateDialog, setShowGenerateDialog] = useState<boolean>(false);
  const [currentExpertId, setCurrentExpertId] = useState<string | undefined>(
    undefined
  );
  const [isGenerateTapped, setIsGenerateTapped] = useState<boolean>(false);

  // Auto populate fields
  const { getFieldValue } = useSubscribeToJob();
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [extractedFields, setExtractedFields] = useState(null);

  const [isExtractingFields, setIsExtractingFields] = useState(false);

  const { handleFileActions } = useFileActions();

  const generateDocumentId = async (individualId: string) => {
    const docRef = await doc(
      collection(db, DATABASE.FORMS, `${individualId}`, "expert_letters")
    );
    if (docRef.id) {
      setDocId(docRef.id);
    }
    return docRef.id;
  };

  const [bstatus, setbstatus] = useState<ExtractionStatus | null>(null);

  const deleteDocument = async (
    userId: string,
    docId?: string | null | undefined,
    showToast = false
  ): Promise<void> => {
    if (!docId) {
      return;
    }

    try {
      await deleteFolderContents(
        `${DATABASE.INDIVIDUALS}/documents/${userId}/Experts/${docId}`
      );
      await onSubmitDeleteResume(docId);
      setResumeUrl(null);
      if (showToast)
        toast({
          title: "Success",
          description: "Resume was deleted successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right"
        });
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(removeResume());
      setShowDeletionDialog(false);
    }
  };
  // Note : Currently, we're not using this saving summary points is included with global form save
  const handleSaveSummaryPoints = async (
    summaryHtml: string,
    summary: string
  ) => {
    try {
      if (!uid) {
        throw new Error("User ID is missing. Please log in again.");
      }

      const expLetterId = docId ?? (await generateDocumentId(uid));

      let filePath;

      if (fileName === null && docId) {
        filePath = activeExpertLetter?.filePath;
      } else {
        filePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Experts/${expLetterId}/${fileName}`;
      }

      const letterData = {
        summaryHtml,
        summary
      };

      if (expLetterId) {
        await onSubmitAddUpdateLetter(letterData, expLetterId);
        setCurrentExpertId(expLetterId);
        navigate(`/individual/${id}/${visaType}/drafts`);
        toast({
          title: "Success",
          description: "Expert inputs was updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right"
        });
      }
    } catch (error: any) {
      console.error("Error updating information:", error);
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while updating information.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right"
      });
      // Optionally, handle additional logic or state updates in case of an error.
    }
  };

  const [temporarySummaryField, setTemporarySummaryField] = useState<
    string | null
  >("");

  const updateExpertName = async (
    expertName: string,
    expertLetterId: string
  ) => {
    try {
      const docRef = doc(
        db,
        `${DATABASE.DRAFTS}/${uid}/expert_letters/${expertLetterId}`
      );
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Document exists, submit the update
        await updateNestedField(
          expertLetterId,
          "extracted_argument.letter_author",
          expertName
        );
      } else {
        console.log("No such document exists!");
      }
    } catch (error) {
      console.error("Error checking document existence: ", error);
    }
  };

  const getInitialExpertLetterValues = (
    activeExpertLetter: Partial<ExpertLetter>
  ) => ({
    expertName: activeExpertLetter?.expertName || "",
    institution: activeExpertLetter?.institution || "",
    relationship: activeExpertLetter?.relationship || "",
    customRelationship: activeExpertLetter?.customRelationship || "",
    expertRole: activeExpertLetter?.expertRole || "",
    expertCurrent: activeExpertLetter?.expertCurrent || "",
    relationshipStartDate: activeExpertLetter?.relationshipStartDate || "",
    relationshipEndDate: activeExpertLetter?.relationshipEndDate || "",
    isWorkedTogether: activeExpertLetter?.isWorkedTogether || false,
    phoneNumber: activeExpertLetter?.phoneNumber || "",
    email: activeExpertLetter?.email || "",
    sharedOrganization: activeExpertLetter?.sharedOrganization || "",
    summary: activeExpertLetter?.summary || "",
    summaryHtml: activeExpertLetter?.summaryHtml || "",
    filePath: activeExpertLetter?.filePath || "",
    docNames: activeExpertLetter?.docNames || "",
    created_at: activeExpertLetter?.created_at || null,
    evidenceIds: activeExpertLetter?.evidenceIds || []
  });

  const formik = useFormik({
    initialValues: getInitialExpertLetterValues(activeExpertLetter!),
    validationSchema: yup.object({
      expertName: yup
        .string()
        .required("Expert's Full name is required")
        .min(1),
      institution: yup.string().required("Institution is required"),
      relationship: yup.string().when("isWorkedTogether", {
        is: true,
        then: (schema) => schema.required("Relationship is required")
      }),
      customRelationship: yup.string().when("relationship", {
        is: "Other",
        then: (schema) => schema.required("Please specify the relationship")
      }),
      expertRole: yup.string(),
      expertCurrent: yup.string().required("Current role is required"),
      phoneNumber: yup
        .string()
        .nullable()
        .test(
          "is-valid-phone-number",
          "Invalid phone number",
          function (value: string | null | undefined) {
            if (value && value.trim() !== "" && value.trim() !== "+1") {
              return isValidPhoneNumber(value);
            }
            return true;
          }
        ),
      sharedOrganization: yup.string().when("isWorkedTogether", {
        is: true,
        then: (schema) =>
          schema.required(
            "Organization where applicant and expert worked together is required"
          )
      })
    }),
    // Custom validation
    validate: (values) => {
      const errors = { summary: "" };

      // Check if file, resumeUrl, or summary is missing
      if (!values.summary || values.summary.trim() === "") {
        setShowHighlightsRequired(true);

        // Scroll to resume highlights
        scrollToElementByRef(highlightsRef);

        // Return error to prevent submission
        errors.summary = "Resume highlights is required.";
        return errors;
      }

      // If there are any Formik errors, scroll to the first one
      const errorFields = Object.keys(formik.errors);
      if (errorFields.length > 0) {
        const firstErrorField = document.getElementById(errorFields[0]);
        if (firstErrorField) {
          scrollToElementByRef(firstErrorField);
          firstErrorField.focus();
        }
      }

      setValidateAfterSubmit(false);

      // If no errors, return an empty object or undefined to proceed with submission
      return {};
    },

    validateOnChange: validateAfterSubmit,
    onSubmit: async (values) => {
      try {
        if (uid) {
          const expLetterId = docId ?? (await generateDocumentId(uid));

          setCurrentExpertId(expLetterId);

          const filePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Experts/${expLetterId}/${fileName}`;

          const letterData = {
            expertName: values.expertName,
            institution: values.institution,
            relationship: values.relationship,
            customRelationship: values.customRelationship,
            expertRole: values.expertRole,
            expertCurrent: values.expertCurrent,
            relationshipStartDate: values.relationshipStartDate,
            relationshipEndDate: values.relationshipEndDate,
            phoneNumber: values.phoneNumber === "+1" ? "" : values.phoneNumber,
            email: values.email,
            sharedOrganization: values?.sharedOrganization,
            uploadedByUid: uid,
            uploadedByName: `${firstName} ${lastName}`,
            expertiseSourceList,
            specificRoleList,
            abilitiesList,
            roleImportanceList,
            isWorkedTogether: values.isWorkedTogether,
            docNames: fileName ?? values.expertName,
            uid: expLetterId,
            filePath: fileName ? filePath : undefined,
            summaryHtml: values.summaryHtml,
            summary: values.summary,
            evidenceIds: selectedEvidenceIds,
            created_at:
              values.created_at !== undefined ? values.created_at! : Date.now(),
            last_updated_at: Date.now()
          };

          if (expLetterId) {
            console.log("letter data in save now", letterData);
            setCurrentExpertId(expLetterId);
            await onSubmitAddUpdateLetter(letterData, expLetterId);
            // Temporary fix to ensure the expert name is correctly applied to the draft
            await updateExpertName(values.expertName, expLetterId);
            setShowGenerateDialog(true);
            dispatch(setActiveExpertLetter(null));
          }
        } else {
          throw new Error("User ID is missing. Please log in again.");
        }
      } catch (error: any) {
        console.error("Error updating information:", error);
        toast({
          title: "Error",
          description:
            error.message || "An error occurred while updating information.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-right"
        });
        // Optionally, handle additional logic or state updates in case of an error.
      }
    }
  });

  const summaryRef = useRef(formik.values.summaryHtml);

  useEffect(() => {
    const handler = setTimeout(() => {
      summaryRef.current = formik.values.summaryHtml;

      if (formik.values.summaryHtml.trim() !== "") {
        setShowHighlightsRequired(false);
      }
    }, 300); // Debounce delay in milliseconds

    // Cleanup function to clear the timeout if the effect runs again before the delay finishes
    return () => {
      clearTimeout(handler);
    };
  }, [formik.values.summaryHtml]);

  useEffect(() => {
    if (activeExpertLetter) {
      formik.setValues(getInitialExpertLetterValues(activeExpertLetter));
    }
  }, [activeExpertLetter, evidenceDocuments]);

  function looksLikeAUSNumber(phoneNumber: string) {
    if (!phoneNumber) {
      return false;
    }

    // Check if the phone number contains a + sign
    if (phoneNumber.includes("+")) {
      return false;
    }

    // Use a regular expression to match all digits in the string
    const digitMatches = phoneNumber.match(/\d/g);

    // Check if there are exactly 10 digits
    return digitMatches && digitMatches.length === 10;
  }

  const handlePopulateFields = async () => {
    const expertId = activeExpertLetter?.uid ?? docId;
    console.log("populate initiated , docId : ", expertId);
    setIsLoadingFields(true);
    try {
      const firestoreRef = doc(
        db,
        DATABASE.FORMS,
        `${uid}`,
        "expert_letters",
        `${expertId}`
      );
      const data = await getFieldValue(firestoreRef, "extracted_fields");
      const summaryFromDb = await getFieldValue(firestoreRef, "summary");
      const summaryHtmlFromDb = await getFieldValue(
        firestoreRef,
        "summaryHtml"
      );
      setExtractedFields(data);

      // Special handling for phone numbers for US numbers
      if (looksLikeAUSNumber(data.resume_phone_number)) {
        data.resume_phone_number = `+1${data.resume_phone_number}`;
      }

      // Set the summary and summaryHtml fields
      // if (summaryFromDb) {
      //   setSummary(summaryFromDb);
      // }
      // if (summaryHtmlFromDb) {
      //   setSummaryHtml(summaryHtmlFromDb);
      // }

      // Set form values
      formik.setValues({
        ...formik.values,
        expertName: data.resume_full_name || formik.values.expertName,
        institution: data.resume_last_organization || formik.values.institution,
        expertCurrent: data.resume_last_role || formik.values.expertCurrent,
        email: data.resume_email_address || formik.values.email,
        phoneNumber: data.resume_phone_number || formik.values.phoneNumber,
        summary: summaryFromDb || formik.values.summary,
        summaryHtml: summaryHtmlFromDb || formik.values.summaryHtml
      });
    } catch (error) {
      toast({
        title: "Error",
        description: `An error occurred while fetching data : ${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right"
      });
    } finally {
      setIsExtractingFields(false);
    }
  };

  const bstatusRef = useRef(bstatus);

  useEffect(() => {
    bstatusRef.current = bstatus;

    if (bstatus?.status || docId) {
      const isProcessing =
        (bstatus?.status &&
          [EXTRACTIONSTATES.Processing, EXTRACTIONSTATES.Pending].includes(
            bstatus.status
          )) ||
        (docId && reducedActiveJobs.includes(docId));

      setIsProcessingInputs(isProcessing || false);
    }
  }, [bstatus, docId]);

  useEffect(() => {
    if (uid && docId) {
      try {
        const docRef = doc(
          collection(db, DATABASE.FORMS, `${uid}`, "expert_letters"),
          docId
        );

        const unsubscribe = onSnapshot(
          docRef,
          (doc) => {
            try {
              if (doc.exists()) {
                const data = doc.data();
                const currentStatus = data.status;
                if (
                  bstatusRef.current?.status === "Processing" &&
                  currentStatus?.status === "Completed"
                ) {
                  console.log("auto populating");
                  handlePopulateFields();
                }
                setbstatus(currentStatus);
              } else {
                console.log("No such document!");
              }
            } catch (error) {
              console.error("Error processing document data:", error);
            }
          },
          (error) => {
            console.error("Error fetching document:", error);
            console.log(uid);
            console.log(docId);
          }
        );

        return () => unsubscribe();
      } catch (error) {
        console.error("Error setting up Firestore snapshot listener:", error);
      }
    }
    return () => {};
  }, [docId, uid]);

  const setNewSpecificRoleList = (v: string[]) => setSpecificRoleList(v);
  const setNewAbilitiesList = (v: string[]) => setAbilitiesList(v);
  const setNewRoleImportanceList = (v: string[]) => setRoleImportanceList(v);
  const setNewExpertiseSourceList = (v: string[]) => setExpertiseSourceList(v);

  const uploadImage = async (docId: string, file: File) => {
    setIsUploadingFile(true);
    if (file && docId) {
      try {
        const filePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/Experts/${docId}`;

        // Delete any existing files at the specified path
        await deleteFolderContents(filePath);

        // Upload the new file
        await onSubmitAddFile(file, `${filePath}/${file.name}`);

        // setSelectedFile(null);
        await onSubmitGetFiles(
          `${DATABASE.INDIVIDUALS}/documents/${uid}/Experts/${docId}`
        );

        // Initially we only submit the file metadata to firestore to trigger auto fields extraction

        const letter: ExpertLetter = {
          uploadDate: Date.now(),
          uploadedByUid: uid,
          uploadedByName: `${firstName} ${lastName}`,
          uid: docId,
          docNames: file.name,
          filePath: `${filePath}/${file.name}`,
          isDeleted: false
        };

        await onSubmitAddUpdateLetter(letter, docId);
      } catch (e) {
        console.log(e);
      } finally {
        // This delay is a little hack just to make the loading seamless as we wait for the BE status to become processing.
        await sleep(4000);
        setIsUploadingFile(false);
        setIsLoadingDoc(false);
        setShowHighlightsRequired(false);
      }
    }
  };

  const allBlocks: ExplanationForm[] = [
    {
      blockTitle:
        "Explain how the expert is familiar with the applicant's work. Incorporate details about collaborative projects, instances where the expert utilized the applicant's work, and any contributions that the expert can verify.",
      list: expertiseSourceList,
      setList: setNewExpertiseSourceList,
      isActive: false,
      index: 0,
      changeActiveBlock: (index: number) => {},
      visaTypes: [
        VISAVALUE.EB1A,
        VISAVALUE.EB1B,
        VISAVALUE.EB2NIW,
        VISAVALUE.O1A,
        VISAVALUE.O1B
      ]
    },
    {
      blockTitle:
        "Describe any specific work or contributions to the field that the expert should emphasize. Include any relevant examples or stories.",
      list: specificRoleList,
      setList: setNewSpecificRoleList,
      isActive: false,
      index: 1,
      changeActiveBlock: (index: number) => {},
      visaTypes: [
        VISAVALUE.EB1A,
        VISAVALUE.EB1B,
        VISAVALUE.EB2NIW,
        VISAVALUE.O1A,
        VISAVALUE.O1B
      ]
    },
    {
      blockTitle:
        "From the expert’s perspective, explain the importance of the applicant's work and the impact it had on the expert directly and/or the field in general. Describe if it is novel in comparison to what previously existed.",
      list: abilitiesList,
      setList: setNewAbilitiesList,
      isActive: false,
      index: 2,
      changeActiveBlock: (index: number) => {},
      visaTypes: [
        VISAVALUE.EB1A,
        VISAVALUE.EB1B,
        VISAVALUE.EB2NIW,
        VISAVALUE.O1A,
        VISAVALUE.O1B
      ]
    },
    {
      blockTitle:
        "Explain why the applicant's work has national importance. Describe what will be the future impact of their work both for the field and the United States.",
      list: roleImportanceList,
      setList: setNewRoleImportanceList,
      isActive: false,
      index: 3,
      changeActiveBlock: (index: number) => {},
      visaTypes: [VISAVALUE.EB2NIW]
    }
  ];

  const filteredBlocks = useMemo(() => {
    if (
      visaType &&
      specificRoleList &&
      abilitiesList &&
      roleImportanceList &&
      expertiseSourceList
    ) {
      const filtered = allBlocks.filter(
        (block: ExplanationForm) =>
          block.visaTypes && block.visaTypes.includes(visaFromPath[visaType])
      );
      return filtered;
    }
    return [];
  }, [
    specificRoleList,
    abilitiesList,
    roleImportanceList,
    expertiseSourceList,
    visaType
  ]);

  // Get the active expert letter from path
  useEffect(() => {
    const fetchData = async () => {
      try {
        const firestoreRef = doc(
          db,
          DATABASE.FORMS,
          `${uid}`,
          "expert_letters",
          `${expertId}`
        );
        const docSnap = await getDoc(firestoreRef);

        if (docSnap.exists()) {
          const data = docSnap.data() as ExpertLetter;

          formik.resetForm();
          dispatch(setActiveExpertLetter(data));

          if (data?.filePath) {
            setIsLoadingExpert(true);

            try {
              const objectURLs = await fetchFiles([data.filePath]);
              setResumeUrl(objectURLs[0]);
            } catch (error) {
              console.error("Error downloading file as blob:", error);
            } finally {
              setIsLoadingExpert(false);
            }
          }
        } else {
          console.log("No document found for expertId:", expertId);
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    if (uid && expertId) {
      fetchData();
    } else {
      console.log("Invalid uid or expertId, skipping fetch.");
    }
  }, [uid, expertId]);

  useEffect(() => {
    if (activeExpertLetter && activeExpertLetter.filePath) {
      setIsLoadingExpert(true);
      fetchFiles([activeExpertLetter.filePath])
        .then((objectURLs) => {
          setResumeUrl(objectURLs[0]);
        })
        .finally(() => {
          setIsLoadingExpert(false);
        })
        .catch((error) => {
          console.error("Error downloading file as blob:", error);
          setIsLoadingExpert(false);
        });
    }
  }, [activeExpertLetter]);

  useEffect(() => {
    setIsLoadingExpert(true);
    if (storageFiles.length) {
      const lastFileRef = storageFiles[storageFiles.length - 1];
      downloadFileAsBlob(lastFileRef)
        .then((objectURL: string) => {
          setResumeUrl(objectURL);
          setFileName(lastFileRef.name);
        })
        .finally(() => {
          setIsLoadingExpert(false);
        })
        .catch((error) => {
          console.error("Error downloading file as blob:", error);
          setIsLoadingExpert(false);
        });
    }
  }, [storageFiles]);

  const handleFileChange = async (
    e?: React.ChangeEvent<HTMLInputElement> | null,
    files?: File[] | null
  ) => {
    const newExpertLetterDocId = docId ?? (await generateDocumentId(uid));
    if (e?.target?.files?.length || files?.length) {
      const file = e?.target?.files?.[0] || files?.[0] || null;

      if (handleUnsupportedExtension(file?.name || "N/A", fileRef)) {
        return; // Exit if the file extension is unsupported
      }
      if (file && newExpertLetterDocId) {
        setSelectedFile(file);
        setIsExtractingFields(true);
        uploadImage(newExpertLetterDocId, file);
      }
    }
  };

  const handleSave = () => {
    setValidateAfterSubmit(true);
    formik.handleSubmit();
  };

  // TODO : Refactor with the default formik submit handler
  const handleSaveForLater = async () => {
    if (!formik.values.summary || formik.values.summary.trim() === "") {
      setShowHighlightsRequired(true);
      // Scroll to resume highlights
      scrollToElementByRef(highlightsRef);
      return;
    }
    const errors = await formik.validateForm();
    const errorFields = Object.keys(formik.errors);
    if (errorFields.length > 0) {
      const firstErrorField = document.getElementById(errorFields[0]);
      if (firstErrorField) {
        scrollToElementByRef(firstErrorField);
        firstErrorField.focus();
      }
    }
    setValidateAfterSubmit(false);
    if (Object.keys(errors).length === 0) {
      try {
        const expLetterId = docId ?? (await generateDocumentId(uid));

        const filePath =
          activeExpertLetter?.filePath ??
          `${DATABASE.INDIVIDUALS}/documents/${uid}/Experts/${expLetterId}/${fileName}`;

        const letterData = {
          expertName: formik.values.expertName,
          institution: formik.values.institution,
          relationship: formik.values.relationship,
          customRelationship: formik.values.customRelationship,
          expertRole: formik.values.expertRole,
          expertCurrent: formik.values.expertCurrent,
          relationshipStartDate: formik.values.relationshipStartDate,
          relationshipEndDate: formik.values.relationshipEndDate,
          phoneNumber:
            formik.values.phoneNumber === "+1" ? "" : formik.values.phoneNumber,
          email: formik.values.email,
          sharedOrganization: formik.values.sharedOrganization,
          expertiseSourceList,
          specificRoleList,
          abilitiesList,
          roleImportanceList,
          isWorkedTogether: formik.values.isWorkedTogether,
          docNames: fileName ?? formik.values.expertName,
          uid: expLetterId,
          filePath: fileName ? filePath : undefined,
          summaryHtml: formik.values.summaryHtml,
          summary: formik.values.summary,
          uploadedByUid: uid,
          uploadedByName: `${firstName} ${lastName}`,
          evidenceIds: selectedEvidenceIds,
          created_at: formik.values.created_at ?? Date.now(),
          last_updated_at: Date.now()
        };

        console.log("letter data in save for later", expLetterId);
        setCurrentExpertId(expLetterId);

        // Submit the data to the server
        await onSubmitAddUpdateLetter(letterData, expLetterId, false);
        // Temporary fix to ensure the expert name is correctly applied to the draft
        await updateExpertName(formik.values.expertName, expLetterId);

        // Show success message and navigate to drafts
        toast({
          title: "Success",
          description: "Expert letter saved.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "bottom-right"
        });
        dispatch(setActiveExpertLetter(null));
        navigate(`/individual/${id}/${visaType}/drafts`);
      } catch (error: any) {
        console.error("Error saving expert letter:", error);
        toast({
          title: "Error",
          description:
            error.message || "An error occurred while saving the letter.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-right"
        });
      }
    } else {
      // Focus on the first field with validation errors
      const firstErrorField = document.getElementById(Object.keys(errors)[0]);
      if (firstErrorField) {
        scrollToElementByRef(firstErrorField);
        firstErrorField.focus();
      }
    }
  };

  const isSummarizingResume = useMemo(() => {
    return (
      bstatus?.status &&
      bstatus?.taskType &&
      [JobType.SummarizingResume, JobType.Extracting].includes(
        bstatus.taskType as JobType
      ) &&
      [EXTRACTIONSTATES.Pending, EXTRACTIONSTATES.Processing].includes(
        bstatus.status
      )
    );
  }, [bstatus?.status, bstatus?.taskType]);

  const onGenerateCancel = async (expertId: string) => {
    setShowGenerateDialog(false);
  };

  // clear active letter after existing edit
  useEffect(() => {
    return () => {
      dispatch(setActiveExpertLetter(null));
    };
  }, []);

  useEffect(() => {
    if (isGenerateTapped) {
      setShowGenerateDialog(false);
    }
  }, [isGenerateTapped]);

  const shouldShowGenerateBtn = !isIndividual;

  return (
    <SectionWrapper>
      <form onSubmit={formik.handleSubmit}>
        <Flex flexDirection="column" rowGap="6px">
          <SpecialHeading
            title={
              expertId
                ? `Edit expert letter : ${expertName}`
                : "Add expert letter"
            }
            backNav
          />
          {/* Resume upload section */}
          {/* TODO : uncomment code */}
          {/* {!isLoadingDoc &&
            !isLoadingExpert &&
            (!resumeUrl || !activeExpertLetter?.filePath) && ( */}
          <CustomBox type="info">
            <Flex flexDirection="column">
              <Text fontSize="18px">
                Start by uploading the expert's resume.
              </Text>
            </Flex>
          </CustomBox>
          <UploadFilesPlaceholder
            isDisabled={isLoadingDoc}
            styleIndex={2}
            title="Drag and drop additional files to bundle with this document here"
            subTitle="Click Here to Upload"
            onUpload={(files) => {
              const filesAsArray = Array.from(files!);
              handleFileChange(null, filesAsArray);
            }}
          />
          {/* )} */}
          {activeExpertLetter && activeExpertLetter?.autoOCRText === "" && (
            <CustomBox type="info">
              <Text fontSize="18px" fontStyle="italic">
                There was an error with the uploaded resume.
              </Text>
            </CustomBox>
          )}

          <Box gap="50px" w="100%">
            {isExtractingFields && (
              <CustomBox type="info">
                <Text fontSize="15px">
                  AI is now extracting the information from the document.
                </Text>
              </CustomBox>
            )}
            {/* Expert's information */}
            <Flex flexDirection="column" width="100%">
              <Heading size="md" color="primary.blue" my={6}>
                Expert's information
              </Heading>
              {isExtractingFields ? (
                <ExpertInfoSkeleton />
              ) : (
                <Flex flexDirection="column" gap={12} mt={4}>
                  <FormControl
                    variant="floating"
                    id="expertName"
                    isInvalid={
                      formik.touched.expertName && !!formik.errors.expertName
                    }
                  >
                    <Input
                      placeholder=" "
                      name="expertName"
                      type="text"
                      value={formik.values.expertName}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>
                      Full name <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormErrorMessage>
                      Expert's Full name is required
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    variant="floating"
                    id="institution"
                    isInvalid={
                      formik.touched.institution && !!formik.errors.institution
                    }
                  >
                    <Input
                      placeholder=" "
                      name="institution"
                      type="text"
                      value={formik.values.institution}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>
                      Organization <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormErrorMessage>Institution is required</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    variant="floating"
                    id="expertCurrent"
                    isInvalid={
                      formik.touched.expertCurrent &&
                      !!formik.errors.expertCurrent
                    }
                  >
                    <Input
                      placeholder=" "
                      name="expertCurrent"
                      type="text"
                      value={formik.values.expertCurrent}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>
                      Current role <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormErrorMessage>
                      Current role is required
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    variant="floating"
                    id="email"
                    isInvalid={formik.touched.email && !!formik.errors.email}
                  >
                    <Input
                      placeholder=" "
                      name="email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>Email</FormLabel>
                    <FormErrorMessage>Email is required</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    variant="floating"
                    id="phoneNumber"
                    isInvalid={
                      formik.touched.phoneNumber && !!formik.errors.phoneNumber
                    }
                  >
                    <Box>
                      <CustomPhoneInput
                        value={formik.values.phoneNumber}
                        onChange={(value) =>
                          formik.setFieldValue("phoneNumber", value)
                        }
                        placeholder="Expert Phone Number"
                      />
                    </Box>
                    <FormErrorMessage>
                      Phone number is required
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
              )}
            </Flex>

            <Divider my={8} />
            {/* Expert's qualifications */}

            <Flex flexDirection="column" gap={4} mt={8} width="100%">
              <Heading size="md" py={2}>
                Expert qualifications <span style={{ color: "red" }}>*</span>
              </Heading>
              {isExtractingFields || isSummarizingResume ? (
                <SkeletonText
                  width="960px"
                  noOfLines={5}
                  spacing="6"
                  skeletonHeight="4"
                />
              ) : (
                <Flex pb={6} flexDirection="column" ref={highlightsRef}>
                  {showHighlightsRequired && (
                    <Flex>
                      <Flex flexDirection="column" my={2} rowGap={2}>
                        <Text color="red" fontWeight={500}>
                          Expert's resume highlights is required to proceed.{" "}
                        </Text>
                      </Flex>
                    </Flex>
                  )}
                  {!resumeUrl && !activeExpertLetter?.filePath && (
                    <CustomBox type="info">
                      <Flex flexDirection="column">
                        <Text>
                          Upload the expert's resume to automatically generate
                          key highlights.
                        </Text>
                      </Flex>
                    </CustomBox>
                  )}
                  <RichTextEditor
                    customHeight="50vh"
                    placeholder="Summarize the expert's qualifications here."
                    text={formik.values.summaryHtml || formik.values.summary}
                    saveText={(html: string, plainText: string) =>
                      handleSaveSummaryPoints(html, plainText)
                    }
                    updateText={(html, text) => {
                      formik.setFieldValue("summary", text || "", false);
                      formik.setFieldValue("summaryHtml", html || "", false);
                    }}
                    shouldShowSaveBtn={false}
                  />
                </Flex>
              )}
            </Flex>
          </Box>

          {/* Worked Together Section */}
          <div className="flex flex-col gap-4 mt-8">
            <FormControl id="isWorkedTogether">
              <Checkbox
                name="isWorkedTogether"
                isChecked={formik.values.isWorkedTogether}
                onChange={formik.handleChange}
                borderColor="black"
              >
                <Text m={6} fontWeight={600} fontSize={22}>
                  Check this box if expert worked with applicant
                </Text>
              </Checkbox>
            </FormControl>
            {formik.values.isWorkedTogether && (
              <>
                <SelectField
                  formikHelpers={[
                    formik.getFieldProps("relationship"),
                    formik.getFieldMeta("relationship")
                  ]}
                  placeHolder="Select one of the options"
                  label="Relationship"
                  name="relationship"
                  options={[
                    "Manager",
                    "Supervisor",
                    "Colleague",
                    "Acquaintance",
                    "Friend",
                    "Other"
                  ]}
                />
                {formik.values.relationship === "Other" && (
                  <FormControl
                    id="customRelationship"
                    isInvalid={
                      formik.touched.customRelationship &&
                      !!formik.errors.customRelationship
                    }
                  >
                    <Input
                      variant="fixed"
                      placeholder=" "
                      name="customRelationship"
                      type="text"
                      value={formik.values.customRelationship}
                      onChange={formik.handleChange}
                    />
                    <FormLabel>
                      Please specify the relationship{" "}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormErrorMessage>
                      Relationship is required
                    </FormErrorMessage>
                  </FormControl>
                )}
                <FormControl
                  variant="fixed"
                  id="expertRole"
                  isInvalid={
                    formik.touched.expertRole && !!formik.errors.expertRole
                  }
                >
                  <FormLabel>Expert's role while working together</FormLabel>
                  <Input
                    placeholder=" "
                    name="expertRole"
                    type="text"
                    value={formik.values.expertRole}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>Expert role is required</FormErrorMessage>
                </FormControl>
                <FormControl
                  variant="fixed"
                  id="sharedOrganization"
                  isInvalid={
                    formik.touched.sharedOrganization &&
                    !!formik.errors.sharedOrganization
                  }
                >
                  <FormLabel>
                    Organization where applicant and expert worked together{" "}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    placeholder=" "
                    name="sharedOrganization"
                    type="text"
                    value={formik.values.sharedOrganization}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage>
                    Organization where applicant and expert worked together is
                    required.
                  </FormErrorMessage>
                </FormControl>
                <FormControl variant="fixed" id="relationshipStartDate">
                  <FormLabel>Relationship start date</FormLabel>
                  <DatePicker
                    date={formik.values.relationshipStartDate}
                    handleChange={(date) => {
                      formik.setFieldValue("relationshipStartDate", date);
                    }}
                  />
                </FormControl>
                <FormControl variant="fixed" id="relationshipEndDate">
                  <FormLabel>Relationship end date</FormLabel>
                  <DatePicker
                    date={formik.values.relationshipEndDate}
                    handleChange={(date) => {
                      formik.setFieldValue("relationshipEndDate", date);
                    }}
                  />
                </FormControl>
              </>
            )}
          </div>
          {/* Evidences dropdown */}
          <Flex flexDirection="column" my={4}>
            <SpecialHeading title="Evidence to Highlight" />
            <Flex flexDirection="column" rowGap={4} py={4}>
              <Text size="sm">
                Optionally, select documents from the uploaded evidence to be
                referenced in the expert letter.
              </Text>
              <Flex w="100%">
                <DropdownGeneric
                  groupBy="criterion"
                  list={evidenceDocuments}
                  checkedItems={activeExpertLetter?.evidenceIds}
                  mergingField="id"
                  searchField="autoTitle"
                  getSelectedItems={(items) => {
                    const evidenceIds = items.map((doc) => doc.id ?? "");
                    if (evidenceIds) setSelectedEvidenceIds(evidenceIds);
                  }}
                />

                {/* <DropdownMulti
                  withToolTip
                  title="No document selected"
                  onItemSelect={(values) => {
                    alert(JSON.stringify(values));
                    setSelectedEvidenceIds(values as Record<string, Item>); // Ensure it's a map
                  }}
                  list={reducedEvidenceMap}
                  selectedValues={selectedEvidenceIds}
                /> */}
              </Flex>
            </Flex>
          </Flex>
          <SpecialHeading title="Expert's questionnaire" />
          <Box w="100%">
            {!filteredBlocks && (
              <CircularProgress
                color="primary.blue"
                isIndeterminate
                height={8}
                w={8}
              />
            )}
            {filteredBlocks &&
              filteredBlocks.map((block, i) => (
                <Box key={i} p="15px" borderWidth="1px" borderRadius="md">
                  <Text
                    fontSize="md"
                    color="primary.blue"
                    fontWeight="bold"
                    mb="10px"
                  >
                    {block.blockTitle}
                  </Text>
                  <Textarea
                    placeholder="Type your answer here..."
                    value={block.list.join("\n")}
                    onChange={(e) => block.setList(e.target.value.split("\n"))}
                    rows={5}
                  />
                </Box>
              ))}
          </Box>
          <Flex m="30px" justifyContent="center" gap={4}>
            <Button
              my={4}
              variant="secondaryOutline"
              type="button"
              mx="15px"
              onClick={() => {
                navigate(`/individual/${id}/${visaType}/drafts`);
              }}
            >
              Cancel
            </Button>
            <Button
              my={4}
              variant="primaryFilled"
              onClick={() => {
                handleSaveForLater();
              }}
              isDisabled={isProcessingInputs}
            >
              Save
            </Button>
            {shouldShowGenerateBtn && (
              <CustomButton
                title="Generate"
                type="ai"
                onClick={handleSave}
                isLoading={isLoadingUpdateFormsData}
                isDisabled={isProcessingInputs}
              />
            )}
          </Flex>
        </Flex>
      </form>
      {/* Modals */}
      {currentExpertId && (
        <ExpertLetterModal
          setIsGenerateTapped={setIsGenerateTapped}
          expertId={currentExpertId}
          onCancel={() => {
            onGenerateCancel(currentExpertId);
          }}
          isOpen={showGenerateDialog && currentExpertId !== undefined}
        />
      )}
    </SectionWrapper>
  );
};
