import { Image } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { lawyerSelectors } from "../redux/lawyer/selectors";
import { SvgLogoIcon } from "./svgLogoItem";
import images from "../assets/image-export-assets";

export const NavBarLogo = () => {
  const lawyer = useSelector(lawyerSelectors.selectAll);

  if (lawyer.loadingLogo) return null;

  return (
    <>
      {!lawyer.lawFirmLogo && <Image src={images.newCaseBlinkLogo} />}
      {lawyer.lawFirmLogo && <Image src={lawyer.lawFirmLogo} height={55} />}
    </>
  );
};
