import { ArrowRightIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { updateDoc } from "firebase/firestore";
import { useRef, useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getEntityReference } from "../helpers/firebase_helpers";
import { deleteCase } from "../redux/clients/clientsSlice";
import { CustomAlertDialog } from "./common/CustomAlertDialog";

type Props = {
  caseId: string;
  visaType: string;
};

export const CaseActions = ({ caseId, visaType }: Props) => {
  const caseRef = getEntityReference("individuals", { individualId: caseId });
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const handleDelete = async (e: any) => {
    e.stopPropagation();
    if (isOpen) {
      setIsDeleting(true);
      await updateDoc(caseRef, { isDeleted: true });
      setIsDeleting(false);
      dispatch(deleteCase(caseId));

      onClose();
    } else {
      onOpen();
    }
  };
  const handleEdit = (e: any) => {
    e.stopPropagation();
    navigate(`/cases/${caseId}/edit-case`);
  };
  const handleNavigate = (e: any) => {
    e.stopPropagation();
    navigate(`/individual/${caseId}/${visaType}/documents/standard-documents`);
  };
  return (
    <>
      <Menu>
        <MenuButton
          _hover={{ bg: "primary.blue", fill: "white" }}
          _expanded={{ bg: "primary.blue", fill: "white" }}
          bg="white"
          as={Button}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FaEllipsisV fill="primary.blue" />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={handleNavigate} icon={<ArrowRightIcon />}>
            Continue to case
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleEdit} icon={<EditIcon />}>
            Edit case
          </MenuItem>
          <MenuItem onClick={handleDelete} icon={<DeleteIcon />}>
            Delete case
          </MenuItem>
        </MenuList>
      </Menu>
      <CustomAlertDialog
        withButton={false}
        alertTitle="WARNING!"
        alertType="delete"
        leastDestructiveRef={cancelRef}
        close={onClose}
        confirm={handleDelete}
        isOpen={isOpen}
        isLoading={isDeleting}
      >
        <Text>
          You are about to permanently delete this case and all data associated
          with it. This cannot be undone.
        </Text>
        <Text mt={2}>Are you sure you want to continue? </Text>
      </CustomAlertDialog>
    </>
  );
};
