import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import {
  getDocumentCategoryTypes,
  mapVisaTypeToPath
} from "../../../helpers/helpers";
import { documentSelectors } from "../../../redux/documents/selectors";
import { VisaDocumentType } from "../../../redux/documents/types";
import { DataDocs } from "../../../types/tables-data";
import Dropdown from "../../common/Dropdown";
import { updateSubDocument } from "../../../redux/documents/documentSlice";

type Props = {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  subDocument: DataDocs | null;
  documentType: VisaDocumentType;
  mainDocument: DataDocs;
};

export const EditSubdocumentModal = ({
  isOpen,
  onClose,
  onOpen,
  subDocument,
  documentType,
  mainDocument
}: Props) => {
  const documentTypes = useSelector(documentSelectors.documentTypes);
  const { visaType } = useParams();
  const mappedVisaType = mapVisaTypeToPath(visaType!);
  const [type, setType] = useState<string | undefined>(subDocument?.type);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setType(subDocument?.type);
  }, [subDocument]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Supporting Document</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {subDocument?.autoTitle ?? ""}
          <Text my={2} fontWeight={500} fontSize={16}>
            Document Type
          </Text>
          <Dropdown
            selectedValue={type}
            title="Type"
            onItemSelect={(value) => {
              setType(value);
            }}
            list={getDocumentCategoryTypes(
              documentTypes,
              documentType,
              mappedVisaType,
              mainDocument.criterion!
            )}
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="secondaryOutline" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            variant="primaryFilled"
            isLoading={isUpdating}
            onClick={async () => {
              setIsUpdating(true);
              const docRef = doc(db, subDocument?.docRef ?? "");
              await updateDoc(docRef, { type });
              setIsUpdating(false);
              if (subDocument)
                dispatch(
                  updateSubDocument({
                    data: {
                      document: { ...subDocument, type },
                      id: mainDocument?.id ?? ""
                    },
                    type: documentType
                  })
                );
              onClose();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
