import { FirmConfiguration } from "./types/firm-configuration.type";

export const URLS = {
  privacy: "https://caseblink.com/privacy-policy",
  tos: "https://caseblink.com/terms-of-service",
  joinListForm: "https://share.hsforms.com/1HLmPa7KOTrqo3yp1OLsmjAq9g78",
  contactForm: "https://share.hsforms.com/1dWUafzlYTPqocsQBihcXowq9g78"
};

export const supportedExtensions = [
  ".pdf",
  ".jpg",
  ".jpeg",
  ".png",
  ".doc",
  ".docx",
  "image/*"
];

export const supportedExtensionsString = supportedExtensions.join(", ");
export const supportedExtensionsString2 = supportedExtensions
  .slice(0, -1)
  .join(", ");

export const firmConfiguration: Record<string, FirmConfiguration> = {
  "jeffries-law": {
    cssForHeader: { background: "#112757" },
    favicon: "/favicons/jeffries-law.ico",
    practiceName: "Jeffries & Corigliano, LLP",
    domainUrl: "https://www.jeffries-law.com/",
    tabTitle: "Jeffries & Corigliano, LLP"
  },
  "fakhouryglobal": {
    cssForHeader: { background: "white" },
    favicon: "/favicons/default.ico",
    practiceName: "employmentimmigration",
    domainUrl: "https://fakhouryglobal.com/",
    tabTitle: "Fakhoury Global Immigration - Immigration Law, Process, Strategy, Advice."
  },
  "canerofadulreis": {
    cssForHeader: { background: "#244e81" },
    favicon: "/favicons/canerofadul.png",
    practiceName: "Canero Fadul Immigration",
    domainUrl: "https://www.canerofadul.com/",
    tabTitle: "Canero Fadul Reis Law | Miami immigration Lawyers"
  }

  // localhost: {
  //   css: { background: "#112757" },
  //   favicon: "/favicons/jeffries-law.ico",
  //   practiceName: "Jeffries & Corigliano, LLP",
  //   domainUrl: "https://www.jeffries-law.com/"
  // }
};
