import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";

import { doc, updateDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../api/firebaseApi";
import useFileActions from "../../../hooks/useFileActions";
import { useGetNewEvidenceDocs } from "../../../hooks/useGetNewEvidenceDocs";
import { documentSelectors } from "../../../redux/documents/selectors";
import { VisaDocumentType } from "../../../redux/documents/types";
import { DATABASE, DataDocs } from "../../../types/tables-data";
import { UploadFilesPlaceholder } from "../../UploadFilesPlaceholder";
import { TableGroupedData } from "../../common/TableGroupedData";
import { TargetBox } from "../../inputDnD/TargetBox";
import EditMainDocModal from "../individualDocuments/EditMainDocModal";
import { signedExpertsLettersColumnsWithActions } from "./SignedExpertLettersColumns";
import { deleteDocument } from "../../../redux/documents/documentSlice";

enum SignedExpertLettersTypes {
  ExpertLetter = "Expert Letter",
  ResumeBio = "Resume/Bio",
  Other = "Other"
}

export const SignedLettersTable = () => {
  const { id: uid, visaType } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = `documents/${uid}/signed_expert_letters`;
  const documentTypes = useSelector(documentSelectors.documentTypes);

  useGetNewEvidenceDocs(path, VisaDocumentType.SignedExpertLetters);

  const docs = useSelector(documentSelectors.signedLetters);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose
  } = useDisclosure();

  const [selectedDocument, setSelectedDocument] = useState<
    DataDocs | undefined
  >(undefined);
  const [foundDocument, setFoundDocument] = useState<DataDocs | undefined>(
    undefined
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const signedExpertLetters = useSelector(documentSelectors.signedLetters);

  const { signedExpertLettersColumns } = signedExpertsLettersColumnsWithActions(
    {
      onDelete: (docId: string) => {
        const foundDocument = signedExpertLetters.find(
          (doc) => doc.id === docId
        );
        if (foundDocument) {
          setSelectedDocument(foundDocument);
        }
        onOpen();
      },
      onEdit: (docId: string) => {
        const foundDocument = signedExpertLetters.find(
          (doc) => doc.id === docId
        );
        if (foundDocument) {
          setFoundDocument(foundDocument);
          setSelectedDocument(foundDocument);
          onEditOpen();
        }
      }
    }
  );

  const storagePath = `${DATABASE.INDIVIDUALS}/documents/${uid}/signed_expert_letters`;

  const { handleFileActions } = useFileActions();

  return (
    <>
      <DndProvider backend={HTML5Backend} context={window}>
        <TargetBox
          onDrop={(item) => {
            const { files } = item;
            handleFileActions({
              files: Array.from(files),
              filePath: storagePath,
              uid: uid!,
              type: VisaDocumentType.SignedExpertLetters
            });
          }}
        >
          <UploadFilesPlaceholder
            styleIndex={docs?.length === 0 ? 0 : 2}
            title="Drag and drop documents here"
            subTitle="OR"
            withImg
            onUpload={(files) => {
              if (files)
                handleFileActions({
                  files: Array.from(files),
                  filePath: storagePath,
                  uid: uid!,
                  type: VisaDocumentType.SignedExpertLetters
                });
            }}
          />
          {docs.length > 0 && (
            <TableGroupedData
              type={VisaDocumentType.SignedExpertLetters}
              columns={signedExpertLettersColumns}
              data={docs}
            />
          )}
          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirmation</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  onClose();
                }}
              />
              <ModalBody pb={6}>
                Are you sure you want to delete the document?
              </ModalBody>

              <ModalFooter>
                <Button variant="secondaryOutline" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="destructiveFilled"
                  ml={3}
                  isLoading={isDeleting}
                  onClick={async () => {
                    setIsDeleting(true);
                    const docRef = doc(db, selectedDocument?.docRef ?? "");
                    await updateDoc(docRef, { isDeleted: true });
                    dispatch(
                      deleteDocument({
                        data: selectedDocument!,
                        type: VisaDocumentType.SignedExpertLetters
                      })
                    );
                    setIsDeleting(false);
                    onClose();
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </TargetBox>
      </DndProvider>
      <EditMainDocModal
        headerText="Editing signed expert letter"
        onClose={onEditClose}
        isOpen={isEditOpen}
        document={selectedDocument}
        isDeleting={isDeleting}
        visaDocumentType={VisaDocumentType.SignedExpertLetters}
      />
    </>
  );
};
