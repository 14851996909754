import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where
} from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../api/firebaseApi";
import {
  addNewDocument,
  deleteDocument,
  setDocuments,
  setIsLoadingGetEvidence,
  updateDocument
} from "../redux/documents/documentSlice";
import { VisaDocumentType } from "../redux/documents/types";
import { useTypedDispatch } from "../redux/store";
import { DataDocs } from "../types/tables-data";
import {
  generateDocumentUrls,
  generateSingleDocumentUrl
} from "../redux/documents/documentsThunk";

export const useGetNewEvidenceDocs = (
  collectionPath: string,
  type: VisaDocumentType,
  documentsPath?: string
) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    const getEvidenceTableOrder = async () => {
      if (documentsPath) {
        const documentRef = doc(db, documentsPath);
        const document = await getDoc(documentRef);
        return document.get("evidenceTableOrder");
      }
      return undefined;
    };
    const fetchDocuments = async () => {
      dispatch(setIsLoadingGetEvidence(true));

      const evidenceOrderTable = await getEvidenceTableOrder();

      
      const collectionRef = collection(db, collectionPath);

      const filteredQuery = query(
        collectionRef,
        where("isDeleted", "==", false)
      );
      
      const querySnapshot = await getDocs(filteredQuery);
      
      const documents = querySnapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            docRef: doc.ref.path,
            sub_documents: [] as DataDocs[],
            isDeleted: doc.data().isDeleted ?? false,
            ...doc.data()
          } as DataDocs)
      );

      if (evidenceOrderTable)
        documents.sort(
          (a, b) =>
            evidenceOrderTable![a?.id ?? ""] - evidenceOrderTable![b.id ?? ""]
        );

      dispatch(setDocuments({ data: documents as DataDocs[], type }));

      dispatch(
        generateDocumentUrls({
          documents: documents.map((doc) => ({
            id: doc.id as string,
            filePath: doc.filePath as string
          })),
          type
        })
      );
      dispatch(setIsLoadingGetEvidence(false));
    };

    const unsubscribe = onSnapshot(
      collection(db, collectionPath),
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const document = {
            ...change.doc.data(),
            docRef: change.doc.ref.path,
            id: change.doc.id,
            isDeleted: change.doc.data().isDeleted ?? false
          } as DataDocs;
          if (change.type === "modified") {
            dispatch(
              updateDocument({
                type,
                data: document
              })
            );
          }
          if (change.type === "added") {
            dispatch(
              addNewDocument({
                type,
                data: document
              })
            );
            dispatch(
              generateSingleDocumentUrl({
                type,
                id: document.id ?? "",
                filePath: document.filePath ?? ""
              })
            ); // Dispatch new thunk
          }
          if (change.type === "removed") {
            dispatch(
              deleteDocument({
                type,
                data: document
              })
            );
          }
        });
      }
    );

    fetchDocuments();

    return () => unsubscribe();
  }, [collectionPath, dispatch]);
};
