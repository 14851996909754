import {
  collection,
  doc,
  documentId,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from "firebase/firestore";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import { fetchFiles, generatePdfThumbnail } from "../helpers/helpers";
import {
  setEvidenceDocs,
  setStandardDocs
} from "../redux/documents/documentSlice";
import { documentSelectors } from "../redux/documents/selectors";
import { ExtractionStatus } from "../redux/extraction-jobs/extractionJobsSlice";
import { individualSelectors } from "../redux/individual/selectors";
import { AddDocProps, DATABASE, DataDocs } from "../types/tables-data";

export type DBData = {
  id: string;
  documents: DataDocs;
};

export const useGetIndividualDocs = () => {
  const { visaType } = useParams();
  const email = useSelector(individualSelectors.selectEmail);
  const dispatch = useDispatch();
  const [isLoadingGetIndividualDocs, setIsLoadingGetIndividualDocs] =
    useState(false);

  const [isLoadingUpdateEvidence, setIsLoadingUpdateEvidence] = useState(false);

  const standardDocuments = useSelector(documentSelectors.standardDocs);
  const evidenceDocuments = useSelector(documentSelectors.evidenceDocs);
  const [docs, setDocs] = useState<DataDocs[]>([]);

  const onSubmitGetStandardDocs = async (indivId: string) => {
    setIsLoadingGetIndividualDocs(true);
    try {
      const documentsRef = collection(
        db,
        DATABASE.DOCUMENTS,
        `${indivId}`,
        "docs"
      );
      const queryRef = query(documentsRef, orderBy("exhibit", "asc"));

      const updatedQuerySnapshot = await getDocs(queryRef);

      const docsData = await Promise.all(
        updatedQuerySnapshot.docs.map(async (doc) => {
          console.log(doc.data());

          const docData = doc.data();
          let thumbnail = null;
          let docUrls = null;
          if (docData.filePath) {
            docUrls = await fetchFiles([docData.filePath]).then(
              (urls) => urls[0] || null
            );

            // Check if the file is a PDF or an image
            if (docData.filePath.endsWith(".pdf") && docUrls) {
              thumbnail = await generatePdfThumbnail(docUrls);
            }
          }

          // Construct and return the document's data structure
          return {
            id: doc.id,
            documents: {
              documentTitle: docData.documentTitle,
              uploadDate: docData.uploadDate,
              uploadBy: docData.uploadBy,
              isRequireForForms: docData.isRequireForForms,
              isRequireForDraft: docData.isRequireForDraft,
              docNames: docData.docNames || "",
              text: docData.text || "",
              exhibit: docData.exhibit || 0,
              description: docData.description || "",
              type: docData.type || "",
              otherDescription: docData.otherDescription || "",
              filePath: docData.filePath || "",
              docUrls: docUrls || "",
              thumbnail: thumbnail || "", // Add the thumbnail field
              autoOCRText: docData.autoOCRText || ""
            }
          };
        })
      );

      dispatch(setStandardDocs(docs));
      setIsLoadingGetIndividualDocs(false);
      return docsData;
    } catch (error) {
      console.error("Error getting documents: ", error);
      return [];
    }
  };

  const onSubmitGetEvidenceDocs = (indivId: string, evidenceId?: string) => {
    setIsLoadingGetIndividualDocs(true);

    try {
      const evidenceDocsRef = collection(
        db,
        DATABASE.DOCUMENTS,
        indivId,
        "evidence_docs"
      );
      let evidenceQuery;

      if (evidenceId) {
        evidenceQuery = query(
          evidenceDocsRef,
          where(documentId(), "==", evidenceId),
          where("isDeleted", "in" ,[false,null] ),
        );
      } else {
        evidenceQuery = query(evidenceDocsRef, orderBy("exhibit", "asc"));
      }

      const unsubscribe = onSnapshot(
        evidenceQuery,
        async (evidenceQuerySnapshot) => {
          const docsDataPromises = evidenceQuerySnapshot.docs.map(
            async (evidenceDoc): Promise<DataDocs | null> => {
              const docsSubCollectionRef = collection(evidenceDoc.ref, "docs");
              const orderedQuery = query(
                docsSubCollectionRef,
                orderBy("exhibit", "asc")
              );
              const subCollectionSnapshot = await getDocs(orderedQuery);
              const subDocs: any[] = [];
              const fetchFilesPromises = subCollectionSnapshot.docs.map(
                async (subDoc) => {
                  try {
                    const files = await fetchFiles([subDoc.data().filePath]);
                    const fileUrl = files[0] ?? "not_found";
                    return {
                      ...subDoc.data(),
                      docUrls: fileUrl,
                      uid: subDoc.id
                    };
                  } catch (error) {
                    console.error("Error fetching files: ", error);
                    return null;
                  }
                }
              );
              const subDocsData = (
                await Promise.all(fetchFilesPromises)
              ).filter(Boolean);
              subDocsData.forEach((docData) => {
                if (docData) {
                  subDocs.push(docData);
                }
              });
              const mainDoc = subDocs.find((doc) => doc.isMain) || null;
              if (!mainDoc) {
                return null;
              }
              return {
                documentTitle: "",
                uploadBy: "",
                uploadDate: 0,
                uid: evidenceDoc.id,
                sub_documents: subDocs,
                mainDoc,
                exhibit: evidenceDoc.data().exhibit,
                autoTitle: evidenceDoc.data().autoTitle || "",
                created_at: evidenceDoc.data().created_at,
                created_by: evidenceDoc.data().created_by,
                extracted_argument: evidenceDoc.data().extracted_argument,
                status: evidenceDoc.data().status as ExtractionStatus,
                isDeleted: evidenceDoc.data().isDeleted
              };
            }
          );

          const docsData = (await Promise.all(docsDataPromises)).filter(
            (doc): doc is DataDocs => doc !== null
          );

          if (evidenceId && docsData.length > 0) {
            setEvidenceDocs([docsData[0]]);
            dispatch(setEvidenceDocs([docsData[0]]));
            unsubscribe(); // Stop listening after getting the initial data if evidenceId is specified
          } else {
            const docs = docsData.length > 0 ? docsData : [];
            setEvidenceDocs(docs);
            dispatch(setEvidenceDocs(docs));
          }
        },
        (error) => {
          console.error("Error getting evidence docs: ", error);
          setIsLoadingGetIndividualDocs(false);
        }
      );
    } catch (error) {
      console.error("Error getting evidence docs: ", error);
      setIsLoadingGetIndividualDocs(false);
    } finally {
      setIsLoadingGetIndividualDocs(false);
    }
  };

  const onSubmitUpdateEvidence = async (
    indivId: string,
    evidenceId: string,
    mainDoc: AddDocProps,
    subDocId: string
  ): Promise<DataDocs | null> => {
    setIsLoadingUpdateEvidence(true);
    try {
      // Reference to the existing document using the provided evidenceId
      const docRef = doc(
        db,
        DATABASE.DOCUMENTS,
        `${indivId}`,
        "evidence_docs",
        `${evidenceId}`
      );

      // Update the main document (without docs)
      await updateDoc(docRef, {
        updated_at: Date.now(),
        last_updated_by: email
      });

      // Reference to the 'docs' subcollection under the main document
      const docsCollectionRef = doc(
        db,
        DATABASE.DOCUMENTS,
        `${indivId}`,
        "evidence_docs",
        `${evidenceId}`,
        "docs",
        `${subDocId}`
      );

      // Update or add mainDoc to the 'docs' subcollection
      await setDoc(docsCollectionRef, mainDoc, { merge: true });

      // // Retrieve the updated document
      // const docSnapshot = await getDoc(docRef);
      // const updatedEvidence = {
      //   uid: docSnapshot.id,
      //   evidence: docSnapshot.data()?.evidence,
      //   updated_at: docSnapshot.data()?.updated_at,
      //   created_by: docSnapshot.data()?.created_by,
      //   exhibit: docSnapshot.data()?.exhibit,
      //   description: docSnapshot.data()?.description,
      //   type: docSnapshot.data()?.type,
      //   prong: docSnapshot.data()?.prong,
      //   docs: evidence.mainDoc ? [evidence.mainDoc] : [],
      // };
      // console.log(updatedEvidence);
      // return updatedEvidence;
    } catch (error) {
      console.error("Error updating evidence document:", error);
    } finally {
      setIsLoadingUpdateEvidence(false);
    }

    return null;
  };

  return {
    onSubmitUpdateEvidence,
    isLoadingUpdateEvidence,
    standardDocuments,
    evidenceDocuments,
    onSubmitGetStandardDocs,
    onSubmitGetEvidenceDocs,
    isLoadingGetIndividualDocs,
    docs
  };
};
