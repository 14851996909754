import { useToast } from "@chakra-ui/react";
import {
  DocumentData,
  DocumentReference,
  collection,
  deleteDoc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { db } from "../api/firebaseApi";
import {
  EXTRACTIONSTATES,
  ExtractionJob,
  ExtractionStatus,
  JobId,
  JobType,
  deleteExtractionJob,
  setCompletedJob,
  setJobs,
  setNewJob,
  updateExtractionJob
} from "../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../redux/extraction-jobs/selectors";
import { DATABASE } from "../types/tables-data";

export const useSubscribeToJob = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const completedJobs = useRef(new Set<string>());
  const subscriptions = useRef(new Map<string, () => void>());

  // Initialize statusCompleteRef and customCallsCompleteRef with Map
  const statusCompleteRef = useRef<Map<string, boolean>>(new Map());
  const customCallsCompleteRef = useRef<Map<string, boolean>>(new Map());

  const jobs = useSelector(extractionSelectors.jobs);

  // Fetch nested status from Firestore document
  const getNestedStatus = (data: DocumentData, fieldPath?: string) => {
    if (!data || !fieldPath) return undefined;
    const nestedData = data[fieldPath];
    return nestedData ? nestedData.status : undefined;
  };

  // Reset document status in Firestore
  const resetDocStatus = async (
    ref: DocumentReference<DocumentData>,
    state: EXTRACTIONSTATES,
    fieldPath?: string
  ): Promise<void> => {
    const statusUpdate = { status: state };
    if (fieldPath) {
      await setDoc(
        ref,
        { [fieldPath]: { status: statusUpdate } },
        { merge: true }
      );
    } else {
      await setDoc(ref, { status: statusUpdate }, { merge: true });
    }
  };

  // Delete a job from Firestore and Redux
  const deleteJob = async (jobId: string) => {
    const job = jobs.find((job) => job.id === jobId);
    if (!job || !job.jobRef) return;

    try {
      await deleteDoc(job.jobRef);
      dispatch(deleteExtractionJob(jobId));
    } catch (error) {
      console.error("Error deleting the job:", error);
    }
  };

  // Reset fields for a job in Firestore
  const resetFields = async (
    job: ExtractionJob,
    state: EXTRACTIONSTATES
  ): Promise<void> => {
    if (!job.docRef || !job.jobRef) {
      console.warn(
        `clear extraction log: Missing docRef or jobRef for job ID: ${job.id}. Aborting reset.`
      );
      return;
    }

    try {
      const jobSnap = await getDoc(job.jobRef);

      if (!jobSnap.exists()) {
        console.warn(
          `clear extraction log: Job document does not exist for jobRef: ${job.jobRef}`
        );
        return;
      }

      await resetDocStatus(job.docRef, state, job.fieldPath);
      await resetDocStatus(job.jobRef, state, job.fieldPath);

      if (state === EXTRACTIONSTATES.NotStarted) {
        deleteJob(job.id);
      }
    } catch (error) {
      console.error(
        `clear extraction log: Error resetting fields for job ID: ${job.id} with state: ${state}`,
        error
      );
    }
  };

  // Persist job information in Firestore
  const persistJobInfo = async (job: ExtractionJob): Promise<void> => {
    if (!job.jobRef) return;

    try {
      const { customCalls, ...jobWithoutCustomCalls } = job;
      await setDoc(job.jobRef, jobWithoutCustomCalls, { merge: true });
    } catch (error) {
      console.error("Error persisting job info:", error);
    }
  };

  // Update job status in Firestore
  const updatePersistedJobStatus = async (
    firestorePath: DocumentReference<DocumentData>,
    newStatus: DocumentData["status"]
  ): Promise<void> => {
    try {
      await updateDoc(firestorePath, { status: newStatus });
    } catch (error) {
      console.error("Error updating job status:", error);
    }
  };

  // Fetch active jobs from Firestore
  const fetchActiveJobs = async (userId: string): Promise<ExtractionJob[]> => {
    setIsLoading(true);
    const jobRecordsRef = collection(db, DATABASE.ACTIVE_JOBS, userId, "jobs");

    try {
      const querySnapshot = await getDocs(jobRecordsRef);
      const extractionJobs = querySnapshot.docs.map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data()
          } as ExtractionJob)
      );

      dispatch(setJobs(extractionJobs));
      return extractionJobs;
    } catch (error) {
      console.error("Error fetching active jobs:", error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  // Determine if a job should trigger a notification
  const shouldListen = (jobId: string, status?: ExtractionStatus): boolean => {
    if (
      jobId === JobId.StandardDocuments &&
      status?.taskType === JobType.SummarizingResume
    ) {
      return true;
    }

    // TODO : improve condition using JobType enum
    if (
      status?.taskType?.includes("Extracting") ||
      status?.taskType?.includes("Generating")
    ) {
      return true;
    }
    return false;
  };

  // Check if both conditions (status and custom calls) are met for a job to complete
  const checkAndCompleteJob = (job: ExtractionJob) => {
    const isStatusComplete = statusCompleteRef.current.get(job.id);
    const isCustomCallsComplete = customCallsCompleteRef.current.get(job.id);

    if (isStatusComplete && isCustomCallsComplete) {
      if (!completedJobs.current.has(job.id)) {
        dispatch(setCompletedJob(job));
        completedJobs.current.add(job.id);
      }
    }
  };

  const customCheck = (jobType: string, status: ExtractionStatus): boolean => {
    if (jobType === "Expert letter draft generation") {
      if (
        status?.status === EXTRACTIONSTATES.Completed &&
        status.taskType === "Extracting"
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  // Subscribe to a job's status updates in Firestore
  const subscribeToJob = (job: ExtractionJob): (() => void) => {
    // Only subscribe to jobs that were initiated by `initiateJob`
    if (!job.isInitiated) {
      return () => {};
    }

    if (!job.jobRef || !job.docRef) {
      console.error("Job reference is null");
      return () => {};
    }

    console.log("Subscribing to job:", job);
    dispatch(updateExtractionJob(job));

    const unsubscribe = onSnapshot(
      job.docRef,
      (docSnapshot) => {
        console.log(`Snapshot received for job ID: ${job.id}`);

        if (!docSnapshot.exists()) {
          console.log(`Document for job ID: ${job.id} does not exist yet.`);
          return;
        }

        const data = docSnapshot.data();

        const currentStatus: ExtractionStatus = job.fieldPath
          ? (getNestedStatus(data, job.fieldPath) as ExtractionStatus)
          : (data?.status as ExtractionStatus);

        // Return if the task shouldn't be in the notifications system
        if (!shouldListen(job.id, currentStatus)) return;

        if (currentStatus && currentStatus?.status !== job.status?.status) {
          console.log(
            `Status change detected for job ID: ${job.id} from ${job.status?.status} to ${currentStatus?.status}`
          );
          dispatch(updateExtractionJob({ ...job, status: currentStatus }));

          if (job.jobRef) {
            updatePersistedJobStatus(job.jobRef, currentStatus);
          }

          if (
            currentStatus?.status === EXTRACTIONSTATES.Completed &&
            customCheck(job.type, currentStatus)
            // && customCheck(job.type, currentStatus)
          ) {
            console.log(`Job ID: ${job.id} completed`);
            if (
              !completedJobs.current.has(job.id) ||
              Object.values(JobId).includes(job.id as JobId)
            ) {
              dispatch(setCompletedJob(job));
              completedJobs.current.add(job.id);
              console.log(`Added job ID: ${job.id} to completed jobs`);
            }
            subscriptions.current.delete(job.id);
            unsubscribe();
          } else if (currentStatus?.status === EXTRACTIONSTATES.Failed) {
            console.log(
              `Job ID: ${job.id} failed with error: ${currentStatus.errorMsg}`
            );
            // toast({
            //   title: job.toastTitle,
            //   description: `The extraction process failed for document: ${job.docName}: ${currentStatus.errorMsg}`,
            //   status: "error",
            //   duration: 3000,
            //   isClosable: true,
            //   position: "bottom-right"
            // });
            unsubscribe();
            subscriptions.current.delete(job.id);
          }
        } else {
          console.log(`No status change for job ID: ${job.id}`);
        }
      },
      (error) => {
        console.error("Firestore subscription error:", error);
      }
    );

    subscriptions.current.set(job.id, unsubscribe);
    return unsubscribe;
  };

  // Handle custom API calls
  const handleCustomCalls = async (job: ExtractionJob) => {
    try {
      await Promise.all(job.customCalls?.map((apiCall) => apiCall()) || []);
      customCallsCompleteRef.current.set(job.id, true);
      checkAndCompleteJob(job);
    } catch (error) {
      console.error("Error during custom calls:", error);
    }
  };

  // Initialize and manage a job's lifecycle
  const initiateJob = async (job: ExtractionJob) => {
    try {
      if (job.docRef && job.jobRef) {
        // Mark the job as initiated
        job.isInitiated = true;

        dispatch(setNewJob(job));
        await persistJobInfo(job);
        await resetFields(job, EXTRACTIONSTATES.Pending);
        subscribeToJob(job);
      }
      if (job.customCalls) {
        await handleCustomCalls(job);
      }
    } catch (error) {
      console.error("Error in initiateJob:", error);
    }
  };

  // Fetch a specific field value from Firestore document
  const getFieldValue = (
    firestoreRef: DocumentReference<DocumentData>,
    fieldName: string
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(
        firestoreRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            resolve(data[fieldName]);
            unsubscribe();
          } else {
            reject(
              new Error("Auto generated expert's data is not available yet.")
            );
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  // Force a job to be marked as completed
  const forceCompleteJob = async (jobId: string) => {
    const job = jobs.find((job) => job.id === jobId);
    if (!job || !job.jobRef) return;

    try {
      const newStatus: DocumentData["status"] = {
        status: EXTRACTIONSTATES.Completed
      };
      await setDoc(job.docRef, { status: newStatus }, { merge: true });
      await setDoc(job.jobRef, { status: newStatus }, { merge: true });

      dispatch(updateExtractionJob({ ...job, status: newStatus }));
      dispatch(setCompletedJob(job));
    } catch (error) {
      console.error("Error completing the task:", error);
    }
  };

  // Clear all active subscriptions
  const clearSubscriptions = () => {
    subscriptions.current.forEach((unsubscribe) => unsubscribe());
    subscriptions.current.clear();
  };

  return {
    shouldListen,
    subscribeToJob,
    initiateJob,
    fetchActiveJobs,
    isLoading,
    getFieldValue,
    forceCompleteJob,
    resetFields,
    deleteJob,
    subscriptions,
    clearSubscriptions
  };
};
