import { mode } from "@chakra-ui/theme-tools";
import { defineStyleConfig } from "@chakra-ui/react";

export const menuTheme = defineStyleConfig({
  baseStyle: (props: any) => ({
    list: {
      borderRadius: "md",
      boxShadow: "lg",
      bg: mode("background.white", "background.darkWhite")(props),
      maxHeight: "300px",
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "10px"
      },
      "&::-webkit-scrollbar-track": {
        background: "primary.lightGray"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "accent.lapis",
        borderRadius: "8px"
      }
    },
    item: {
      px: 4,
      py: 4,
      mx: 0,
      bg: mode("background.white", "background.darkWhite")(props),
      color: mode("text.display.light", "text.display.dark")(props),
      _hover: {
        bg: mode("primary.focusOutline", "primary.hoverOpacity")(props)
      },
      _focus: {
        bg: mode("primary.hoverOpacity", "primary.focusOutline")(props)
      }
    }
  }),

  // Defining the default variant
  variants: {
    outline: (props: any) => ({
      list: {
        borderColor: mode("primary.lightGray", "primary.backgroundDark")(props)
      }
    }),
    toolbar: (props: any) => ({
      list: {},
      item: {
        width: "100% !important",
        display: "inline-flex",
        float: "unset",
        _hover: {
          bg: mode("primary.focusOutline", "primary.hoverOpacity")(props),
          background: mode(
            "primary.focusOutline !important",
            "primary.hoverOpacity !important"
          )(props)
        }
      }
    })
  },

  // Setting the default variant
  defaultProps: {
    variant: "outline" // Default variant
  }
});
