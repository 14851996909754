import { defineStyleConfig } from "@chakra-ui/react";

export const tabsTheme = defineStyleConfig({
  baseStyle: {
    tab: {
      fontWeight: "600",
      color: "text.graySecondary.smog",
      _selected: {
        fontWeight: "600",
        color: "primary.blue",
        borderColor: "primary.red"
      },
      _hover: {
        color: "primary.blue"
      },
      _focus: {
        boxShadow: "none"
      },
      _disabled: {
        color: "stroke.stroke1Light"
      }
    },
    tablist: {
      fontWeight: 600,
      borderBottom: "2px solid",
      borderColor: "stroke.stroke3"
    },
    indicator: {
      height: "3px",
      bg: "primary.red"
    },
    tabpanel: {
      marginTop: "16px"
    }
  },
  sizes: {
    md: {
      tab: {
        fontSize: "16px"
        // px: "16px",
      }
    }
  },
  variants: {
    underline: {
      tab: {
        _selected: {
          color: "primary.blue",
          fontWeight: "600"
        },
        _hover: {
          color: "primary.blue"
        },
        _disabled: {
          color: "stroke.stroke1Light"
        },
        _inactive: {
          color: "text.graySecondary.smog"
        }
      },
      indicator: {
        bg: "primary.red",
        height: "3px"
      }
    }
  },
  defaultProps: {
    variant: "underline",
    size: "md"
  }
});
