import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {
  ColumnDef,
  ExpandedState,
  GroupingState,
  flexRender,
  getCoreRowModel,
  getGroupedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from "@dnd-kit/modifiers";
import { useDispatch } from "react-redux";
import { SortableContext } from "@dnd-kit/sortable";
import { doc, setDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import {
  moveDocument,
  setEvidenceDocs,
  setEvidenceExhibitMap,
  setStandardExhibitMap
} from "../../redux/documents/documentSlice";
import { DataDocs, TableData } from "../../types/tables-data";
import { VisaDocumentType } from "../../redux/documents/types";
import { DraggableDiv, DraggableRow, RowDragHandleCell } from "./DraggableRow";
import { db } from "../../api/firebaseApi";

type EvidenceTableOrderMap = {
  category: string;
  todosId: string[];
};
type TableGroupedDataProps<T extends TableData> = {
  data: T[];
  columns: ColumnDef<T, any>[];
  enableGrouping?: boolean;
  includeExhibits?: boolean;
  type: VisaDocumentType;
};

const createIndexList = (data: any) => {
  return data.map((_: any, index: any) => index + 1);
};

export const TableGroupedData = <T extends TableData>({
  columns,
  data,
  enableGrouping = false,
  includeExhibits = false,
  type
}: TableGroupedDataProps<T>) => {
  const { id } = useParams();
  const [grouping, setGrouping] = useState<GroupingState>(
    enableGrouping ? ["criterion"] : []
  );
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const indexedData = useMemo(() => createIndexList(data), [data]);
  const table = useReactTable<T>({
    columns,
    data,
    state: {
      grouping,
      expanded
    },
    onGroupingChange: setGrouping,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    autoResetExpanded: false,
    initialState: {
      expanded: true,
      columnVisibility: {
        criterion: true
      }
    },
    debugAll: true
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (includeExhibits) {
      const exhibitRows = table.getRowModel();
      const exhibitMap = exhibitRows.rows.reduce((acc, row, rowIndex) => {
        row.subRows.forEach((subRow, subRowIndex) => {
          const key = `${rowIndex + 1}.${subRowIndex + 1}`;
          acc[key] = (subRow.original as any).id;
        });
        return acc;
      }, {} as Record<string, string>);
      switch (type) {
        case VisaDocumentType.Evidence:
          dispatch(setEvidenceExhibitMap(exhibitMap));
          break;
        case VisaDocumentType.Standard:
          dispatch(setStandardExhibitMap(exhibitMap));
          break;
        default:
          break;
      }
    }
  }, [data, table]);

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {})
  );

  useEffect(() => {
    if (type === VisaDocumentType.Evidence) {
      const updateCurrentOrder = () => {
        const order: Record<string, number> = {};
        data.forEach((todo, i) => {
          order[todo?.id ?? ""] = i;
        });
        const docRef = doc(db, `/documents/${id!}`);
        const evidenceRows = table.getRowModel().rows;
        const todosOrderMap: any[] = evidenceRows.map((evidence) => ({
          category: (evidence.original as any)?.criterion ?? "",
          evidenceIds: evidence.subRows.map((todo) => todo.original.id)
        }));

        setDoc(docRef, {
          evidenceTableOrder: order,
          evidenceTableOrderMap: todosOrderMap
        });
      };
      updateCurrentOrder();
    }
  }, [data]);
  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const isActiveCategory = active.id.toString().includes("type:");
      const isOverCategory = over?.id.toString().includes("type:");
      if (isActiveCategory && isOverCategory) {
        const activeCategory = active.id;
        const overCategory = over?.id;

        const activeCategoryPawn = table
          .getRowModel()
          .rows.find((value) => value.id === activeCategory)?.original.id;
        const overCategoryPawn = table
          .getRowModel()
          .rows.find((value) => value.id === overCategory)?.original.id;
        const oldIndex = data.findIndex(
          (item) => item.id === activeCategoryPawn
        );
        const newIndex = data.findIndex((item) => item.id === overCategoryPawn);

        dispatch(moveDocument({ newIndex, oldIndex }));
      } else if (
        !isActiveCategory &&
        !isOverCategory &&
        over?.id !== undefined
      ) {
        const oldIndex = data.findIndex((item) => item.id === active.id);
        const newIndex = data.findIndex((item) => item.id === over?.id);

        dispatch(moveDocument({ newIndex, oldIndex }));
      }
    }
    // setIsDragging(false);
  }
  // console.table(JSON.stringify(table.getRowModel(), null, 2));
  return (
    <TableContainer rounded="md">
      <DndContext
        onDragEnd={(e) => {
          console.log("disable this function until full integration");
        }}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        sensors={sensors}
      >
        <Table
          variant="simple"
          p="15px"
          __css={{
            width: "100%",
            wordWrap: "break-word",
            whiteSpace: "normal",
            border: "1px solid", // Ensure borders are defined for all sides
            borderColor: "gray.200", // Set a border color to ensure visibility
            borderRadius: "md", // Rounded corners for the entire table
            overflow: "hidden" // Ensures rounded corners are visible
          }}
        >
          <Thead>
            <Tr>
              {includeExhibits && <Th>Exhibit</Th>}
              {table
                .getHeaderGroups()
                .map((headerGroup) =>
                  headerGroup.headers.map((header) =>
                    header.id !== "criterion" ? (
                      <Th key={header.id}>
                        {header?.column?.columnDef.header?.toString()}
                      </Th>
                    ) : null
                  )
                )}
            </Tr>
          </Thead>
          <Tbody>
            {/* SortableContext for Categories */}
            <SortableContext
              key="group"
              items={table
                .getRowModel()
                .rows.map((groupedRow) => ({ id: groupedRow.id }))}
            >
              {table.getRowModel().rows.map((row, index) => (
                <>
                  {enableGrouping && (
                    <DraggableDiv row={row} key={row.id}>
                      {/* <Td>
                        <RowDragHandleCell rowId={row.id} key={row.id} />
                      </Td> */}
                      {row.getVisibleCells().map((cell) => (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      ))}
                    </DraggableDiv>
                  )}

                  {/* SortableContext for subrows */}
                  <SortableContext
                    key={`sub-${row.id}`}
                    items={[
                      ...row.subRows.map((row) => ({
                        id: row.original.id ?? ""
                      }))
                    ]}
                  >
                    {(row.getIsExpanded() || !enableGrouping) &&
                      row.subRows.map((subrow, subRowIndex) => (
                        <DraggableRow row={subrow} key={subrow.original.id}>
                          {includeExhibits && (
                            <Td>
                              <div className="flex items-center">
                                {/* <RowDragHandleCell
                                  rowId={subrow.original.id ?? ""}
                                  key={subrow.original.id}
                                /> */}
                                <p>
                                  {index + 1}.{indexedData[subRowIndex]}
                                </p>
                              </div>
                            </Td>
                          )}
                          {subrow
                            .getVisibleCells()
                            .map((cell) =>
                              cell.column.id !== "criterion" ? (
                                <Td key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext() as any
                                  )}
                                </Td>
                              ) : null
                            )}
                        </DraggableRow>
                      ))}
                  </SortableContext>
                  {!enableGrouping && (
                    <Tr key={row.id}>
                      {includeExhibits && <Td>{index + 1}</Td>}
                      {row.getVisibleCells().map((cell) => (
                        <Td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      ))}
                    </Tr>
                  )}
                </>
              ))}
            </SortableContext>
          </Tbody>
        </Table>
      </DndContext>
    </TableContainer>
  );
};
