/* eslint-disable no-case-declarations */
import { doc } from "firebase/firestore";
import { auth, db } from "../api/firebaseApi";
import { VisaDocumentType } from "../redux/documents/types";

type FirebaseFunction<T> = (...args: any[]) => Promise<T>;

export const guardedFirebaseRequest = async <T>(
  firebaseFunction: FirebaseFunction<T>,
  ...args: any[]
): Promise<T> => {
  const { currentUser } = auth;
  if (!currentUser) {
    throw new Error("User is not authenticated");
  }

  try {
    return await firebaseFunction(...args);
  } catch (error) {
    console.error("Firebase request failed:", error);
    throw error;
  }
};

export const getDocRefByVisaType = (
  visaType: VisaDocumentType,
  uid: string,
  docId: string
) => {
  let docTypePath = "";
  if (visaType === VisaDocumentType.Evidence) {
    docTypePath = "evidence_docs";
  }
  if (visaType === VisaDocumentType.Standard) {
    docTypePath = "docs";
  }
  if (visaType === VisaDocumentType.SignedExpertLetters) {
    docTypePath = "signed_expert_letters";
  }

  const docRef = doc(db, `/documents/${uid}/${docTypePath}/${docId}`);
  return docRef;
};

type EntityAttributesMap = {
  individuals: { individualId: string };
  lawyers: { lawyerId: string };
  documents: { documentId: string };
};

export const getEntityReference = <T extends keyof EntityAttributesMap>(
  entity: T,
  id: EntityAttributesMap[T]
) => {
  let path = "";
  switch (entity) {
    case "individuals":
      const { individualId } = id as EntityAttributesMap["individuals"];
      path = `/individuals/${individualId}`;
      break;
    case "documents":
      const { documentId } = id as EntityAttributesMap["documents"];
      path = `/documents/${documentId}`;
      break;
    // TODO : complete the remaining list of entities our app has

    default:
      path = "";
  }
  const docRef = doc(db, path);
  return docRef;
};
