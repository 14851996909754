import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

// Filled Icon Button
const filledIconButton = defineStyle({
  bg: "transparent",
  color: "primary.blue",
  _hover: {
    color: "white",
    bg: "primary.blue",
    opacity: "0.75"
  },
  _pressed: {
    bg: "primary.blue",
    textOverlay: "#0000001F"
  },
  _disabled: {
    bg: "primary.lightGray",
    cursor: "not-allowed",
    opacity: "0.5"
  }
});

// Outline Icon Button
const outlineIconButton = defineStyle({
  bg: "transparent",
  color: "primary.blue",
  _hover: {
    bg: "transparent",
    opacity: "0.75"
  },
  _pressed: {
    bg: "transparent",
    opacity: "0.75"
  },
  _disabled: {
    color: "primary.lightGray",
    cursor: "not-allowed",
    opacity: "0.5"
  }
});

// Destructive Icon Button
const destructiveIconButton = defineStyle({
  bg: "transparent", // Transparent background
  color: "primary.red", // Destructive (red) icon color
  _hover: {
    bg: "#FFE5E5", // Light red background on hover
    opacity: "0.85"
  },
  _pressed: {
    bg: "#FFE5E5", // Slightly darker red on press
    opacity: "0.7"
  },
  _disabled: {
    color: "primary.lightGray",
    cursor: "not-allowed",
    opacity: "0.5"
  }
});

// Primary button styles
const primaryFilled = defineStyle({
  bg: "primary.blue",
  color: "white",
  _hover: {
    bg: "primary.blue",
    opacity: "0.75"
  },
  _pressed: {
    bg: "primary.blue",
    textOverlay: "#0000001F"
  },
  _disabled: {
    bg: "primary.lightGray",
    background: "primary.lightGray",
    cursor: "not-allowed",
    opacity: "0.5",
    _hover: { background: "primary.lightGray !important" }
  },
  _destructive: {
    bg: "primary.red", // Correct the background to red for destructive actions
    color: "white", // Make sure the text is white in destructive state
    _hover: {
      bg: "primary.red",
      opacity: "0.85"
    },
    _pressed: {
      bg: "primary.red",
      opacity: "0.7"
    }
  }
});

// Destructive button styles
const destructiveFilled = defineStyle({
  bg: "primary.red",
  color: "white",
  _hover: {
    bg: "primary.red",
    opacity: "0.85"
  },
  _pressed: {
    bg: "primary.red",
    opacity: "0.7"
  },
  _disabled: {
    bg: "primary.lightGray",
    cursor: "not-allowed",
    opacity: "0.5"
  }
});

const destructiveOutline = defineStyle({
  borderColor: "primary.red",
  color: "primary.red",
  bg: "transparent",
  borderWidth: "2px",
  _hover: {
    bg: "#FFE5E5",
    opacity: "0.85"
  },
  _pressed: {
    bg: "#FFE5E5",
    opacity: "0.7"
  },
  _disabled: {
    borderColor: "primary.lightGray",
    color: "primary.lightGray",
    cursor: "not-allowed",
    opacity: "0.5"
  }
});

// Primary outline styles
const primaryOutline = defineStyle({
  borderColor: "primary.blue",
  color: "primary.blue",
  borderWidth: "2px",
  _hover: {
    bg: "primary.focusOutline",
    opacity: "0.5"
  },
  _pressed: {
    bg: "primary.focusOutline"
  },
  _disabled: {
    borderColor: "primary.lightGray",
    color: "primary.lightGray",
    opacity: "0.5",
    cursor: "not-allowed"
  }
});

// Secondary button styles
const secondaryFilled = defineStyle({
  bg: "transparent",
  color: "primary.blue",
  borderColor: "primary.blue",
  borderWidth: "2px",
  _hover: {
    bg: "#DFF1FB",
    opacity: "0.5"
  },
  _pressed: {
    bg: "#DFF1FB"
  },
  _disabled: {
    bg: "transparent",
    borderColor: "#B8C0C9",
    color: "#B8C0C9",
    cursor: "not-allowed",
    opacity: "0.5"
  },
  _destructive: {
    bg: "transparent",
    borderColor: "primary.red",
    color: "primary.red"
  }
});

const secondaryOutline = defineStyle({
  borderColor: "primary.blue",
  color: "primary.blue",
  borderWidth: "2px",
  _hover: {
    bg: "#DFF1FB",
    opacity: "0.5"
  },
  _pressed: {
    bg: "#DFF1FB"
  },
  _disabled: {
    borderColor: "#B8C0C9",
    color: "#B8C0C9",
    cursor: "not-allowed",
    opacity: "0.5"
  },
  _destructive: {
    borderColor: "primary.red",
    color: "primary.red"
  }
});

// Primary and Secondary Text button styles
const primaryText = defineStyle({
  color: "primary.blue",
  bg: "transparent",
  _hover: {
    textDecoration: "underline"
  },
  _disabled: {
    color: "primary.lightGray",
    opacity: "0.5",
    cursor: "not-allowed"
  }
});

const secondaryText = defineStyle({
  color: "primary.blue",
  bg: "transparent",
  _hover: {
    textDecoration: "underline"
  },
  _disabled: {
    color: "primary.lightGray",
    opacity: "0.5",
    cursor: "not-allowed"
  }
});

const add = defineStyle({
  // Button/Tag styles reused
  borderRadius: "sm !important",
  border: "1px solid",
  borderColor: "stroke.stroke1Light",
  color: "primary.blue",
  bg: "transparent",
  width: "auto",
  height: "40px",
  display: "inline-flex", // Flex layout for tag
  alignItems: "center",
  _hover: {
    bg: "primary.focusOutline",
    color: "primary.blue"
  },
  _active: {
    bg: "primary.focusOutline",
    color: "primary.blue"
  },
  _disabled: {
    borderColor: "primary.lightGray",
    color: "primary.lightGray",
    cursor: "not-allowed",
    opacity: "0.5"
  }
});

// Define the button theme configuration
export const buttonTheme = defineStyleConfig({
  baseStyle: {
    rounded: "full",
    fontWeight: "500"
  },
  sizes: {
    sm: {
      fontSize: "14px",
      padding: "12px 24px"
    },
    md: {
      fontSize: "16px",
      padding: "16px 32px"
    },
    lg: {
      fontSize: "18px",
      padding: "20px 40px"
    }
  },

  variants: {
    add,
    primaryFilled,
    primaryOutline,
    primaryText,
    secondaryFilled,
    secondaryOutline,
    secondaryText,
    destructiveFilled,
    destructiveOutline,
    filledIconButton,
    outlineIconButton,
    destructiveIconButton
  },
  defaultProps: {
    size: "md",
    variant: "primaryFilled"
  }
});
