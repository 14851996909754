import { Divider, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useExpertLetterDrafts } from "../../hooks/useExpertLetterDrafts";
import { useGetFormsData } from "../../hooks/useGetFormsData";
import { ExhibitListSection } from "./individualDrafts/ExhibitListSection";
import { ExpertLetterDraftsSection } from "./individualDrafts/ExpertLettersDraftSection";
import { SopDraftSection } from "./individualDrafts/SopDraftSection";

interface Props {
  mainTabIndex?: number | null;
  subTabIndex?: number | null;
}

export const IndividualDrafts = ({ mainTabIndex, subTabIndex }: Props) => {
  const { id } = useParams();

  useExpertLetterDrafts(id!);

  useGetFormsData();

  return (
    <div className="w-fulll h-full">
      <VStack
        alignItems="stretch"
        gap={8}
        divider={<Divider my={8} />}
        w="100%"
      >
        <ExpertLetterDraftsSection />
        {/* <CoverLetterDraftsSection /> */}
        <ExhibitListSection />
        <SopDraftSection />
      </VStack>
    </div>
  );
};
