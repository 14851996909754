import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Text
} from "@chakra-ui/react";
import { useRef } from "react";
import { IoFolderOpen, IoFolderOpenOutline } from "react-icons/io5";
import images from "../assets/image-export-assets";
import { supportedExtensionsString2 } from "../constans";

interface FileUploaderProps {
  title: string;
  subTitle: string;
  onUpload: (files: FileList | null) => void;
  withImg?: boolean;
  styleIndex?: number;
  isDisabled?: boolean;
}

export const UploadFilesPlaceholder = ({
  title,
  subTitle,
  onUpload,
  withImg = false,
  styleIndex = 0,
  isDisabled = false
}: FileUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  // Define the styles for each variant
  const styles = [
    {
      container: {
        bg: "primary.backgroundLight",
        border: "2px dashed",
        borderColor: "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center" as const
      },
      buttons: {
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        marginTop: "20px"
      }
    },
    {
      container: {
        bg: "",
        border: "2px dashed",
        borderColor: "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        textAlign: "center" as const
      },
      text: {
        fontWeight: "bold",
        color: "primary.darkBlue",
        marginBottom: "10px"
      }
    },
    {
      container: {
        bg: "bgColor.200",
        border: "2px dashed",
        borderColor: "#ebedf0",
        borderRadius: "8px",
        padding: "20px",
        display: "flex" as const,
        flexDirection: "column" as const,
        alignItems: "center" as const,
        textAlign: "center" as const
      },
      buttons: {
        display: "flex",
        gap: "10px"
      }
    }
  ];

  const style = styles[styleIndex] || styles[0]; // Fallback to the first style if the index is invalid

  return (
    <Flex flexDirection="column" textAlign="start">
      <Box
        bg={styleIndex === 2 ? "background.cloud" : ""}
        style={style.container}
        cursor="pointer"
        onClick={handleClick}
        _hover={{ bg: "primary.backgroundLight" }}
      >
        {styleIndex === 0 && (
          <Flex flexDirection="column" alignItems="center">
            <Flex justifyContent="start">
              {" "}
              {withImg && (
                <Image src={images.fileupload} height="120px" mb={4} />
              )}
            </Flex>
            <Text mt={2} fontWeight={600}>
              {title}
            </Text>
            <Text mt={2} fontWeight={600}>
              {subTitle}
            </Text>
            <Box style={style.buttons}>
              <Button leftIcon={<IoFolderOpen />} variant="primaryFilled">
                Upload files
              </Button>
              {/* Add any other buttons if needed */}
            </Box>
            <Text
              mt={8}
              fontSize="sm"
              fontWeight={500}
              color="text.graySecondary.smog"
            >
              Supported formats: {supportedExtensionsString2}
            </Text>
          </Flex>
        )}
        {styleIndex === 1 && (
          <>
            <Text style={style.text} fontWeight="bold">
              Drag and drop here or{" "}
              <span style={{ color: "primary.darkBlue" }}>
                Upload Document(s)
              </span>
            </Text>
            <Text fontSize="sm" color="secondary.darkGray">
              {supportedExtensionsString2}
            </Text>
          </>
        )}
        {styleIndex === 2 && (
          <>
            <Flex columnGap={4} alignItems="center" textAlign="center">
              <Text fontWeight="600">Drag and drop here</Text>
              <Text mx={4}>OR</Text>
              <Box style={style.buttons}>
                <Button
                  leftIcon={<IoFolderOpen />}
                  size="sm"
                  variant="secondaryOutline"
                >
                  Upload files
                </Button>
                <Divider orientation="vertical" />
                {/* <Button variant="secondaryOutline" size="sm">
                Upload by Category
              </Button> */}
              </Box>
            </Flex>
            <Text mt={2} fontSize="sm" color="secondary.darkGray">
              Supported formats: .pdf, .jpg, .jpeg, .png, .docx, image/*
            </Text>
          </>
        )}
        <input
          disabled={isDisabled}
          onChange={(e) => onUpload(e.target.files)}
          className="hidden"
          multiple
          type="file"
          ref={fileInputRef}
        />
      </Box>
    </Flex>
  );
};

export default UploadFilesPlaceholder;
