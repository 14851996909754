import { DeleteIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Skeleton, Tooltip } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { openFilePreview } from "../../../helpers/helpers";
import { getShortString } from "../../../helpers/string_helpers";
import { DataDocs } from "../../../types/tables-data";

const columnHelper = createColumnHelper<DataDocs>();

type ActionsCallBack = {
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  isGroupingEnabled?: boolean;
};
export const signedExpertsLettersColumnsWithActions = ({
  onDelete,
  onEdit,
  isGroupingEnabled = false
}: ActionsCallBack) => {
  const signedExpertLettersColumns: ColumnDef<DataDocs, any>[] = [
    // columnHelper.accessor("orderedIndex", {
    //   cell: ({ row, cell, getValue }) => (row.depth !== 0 ? getValue() : ""),
    //   header: "Exhibit"
    // }),
    columnHelper.accessor("autoTitle", {
      cell: ({ row, getValue }) => {
        const value = getValue();
        if (isGroupingEnabled ? row.depth === 1 : row.depth === 0) {
          return (
            <Tooltip label={row.original.autoTitle}>
              <Flex w="100%" alignItems="center">
                {value ? (
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      whiteSpace: "normal",
                      maxWidth: "100%"
                    }}
                  >
                    {value}
                  </div>
                ) : (
                  <Skeleton w={20} h={8} />
                )}
                <IconButton
                variant="filledIconButton"
                  icon={<ExternalLinkIcon />}
                  onClick={() => openFilePreview(row.original.docUrl)}
                  ml={2}
                  cursor="pointer" // Use cursor style directly
                  aria-label="Open Preview"
                />
              </Flex>
            </Tooltip>
          );
        }
        return null;
      },
      header: "Document Title"
    }),
    columnHelper.accessor("status.status", {
      cell: (info) => info.getValue(),
      header: "Status"
    }),
    columnHelper.display({
      id: "delete",
      header: "Actions",
      cell: ({ row }) =>
        (isGroupingEnabled ? row.depth === 1 : row.depth === 0) && (
          <Flex gap={2}>
            <IconButton
              variant="destructiveIconButton"
              icon={<DeleteIcon />}
              onClick={() => {
                onDelete(row.original.id!);
              }}
              aria-label=""
            />
            <IconButton
              variant="filledIconButton"
              icon={<EditIcon />}
              onClick={() => {
                onEdit(row.original.id!);
              }}
              aria-label=""
            />
          </Flex>
        )
    })
  ];

  return {
    signedExpertLettersColumns
  };
};
