import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

export const tablesTheme = defineMultiStyleConfig({
  // Define the parts you're going to style
  baseStyle: {
    table: {
      borderRadius: "md", // Apply rounded corners to the entire table
      border: "1px solid", // Consistent border around the table
      borderColor: "gray.200", // Adjust border color as per your theme
      overflow: "hidden", // Ensures rounded corners are visible
    },
    thead: {
      bg: "primary.backgroundLight",
      borderTopRadius: "md", // Rounded top for the table head
    },
    tr: {
      borderBottom: "1px solid", // Border between rows
      borderColor: "gray.200",
      borderLeft: "1px solid", // Consistent left border for rows
      borderRight: "1px solid", // Consistent right border for rows
      color: "text.display.light",
      fontWeight: "500",
    },
    td: {
      borderBottom: "1px solid", // Bottom border for table cells
      borderColor: "gray.200",
    },
  },
});
