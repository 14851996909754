import { Box, Divider, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { doc } from "firebase/firestore";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { summarizeResumeWithUpdate } from "../../api/OpenAIApi";
import { db } from "../../api/firebaseApi";
import { documentsTypes, formatPassport } from "../../helpers/helpers";
import { useAddExtractInfo } from "../../hooks/useAddExtractInfo";
import { useDeleteExtractedInfo } from "../../hooks/useDeleteExtractedInfo";
import { useExtractData } from "../../hooks/useExtractData";
import { useGetIndividualDocs } from "../../hooks/useGetIndividualDocs";
import { useSubscribeToJob } from "../../hooks/useSubscribeToJob";
import { DocumentReducer } from "../../redux/documents/documentSlice";
import { documentSelectors } from "../../redux/documents/selectors";
import {
  ExtractionJob,
  ExtractionReducer,
  EXTRACTIONSTATES,
  ExtractionStatesDescription
} from "../../redux/extraction-jobs/extractionJobsSlice";
import { extractionSelectors } from "../../redux/extraction-jobs/selectors";
import { individualSelectors } from "../../redux/individual/selectors";
import { ExtractedPassport } from "../../types/extracted-passport";
import { DATABASE } from "../../types/tables-data";
import { StandardDocumentsTable } from "../StandardDocumentsTable";
import { CustomButton } from "../common/CustomButton";
import { ExtractionCard } from "../common/ExtractionCard";
import { SpecialHeading } from "./PanelLayout";
import { AlreadyExtractedDialog } from "./individualDocuments/AlreadyExtractedDialog";
import DeleteEvidenceExtractionDialog from "./individualDocuments/DeleteEvidenceExtractionDialog";
import { DocumentsInfoModal } from "../common/DocumentsInfoModal";

interface Props {
  mainTabIndex?: number | null;
  subTabIndex?: number | null;
}

export const StandardDocs = ({ mainTabIndex, subTabIndex }: Props) => {
  const { id, caseId } = useParams();
  const toast = useToast();
  const { initiateJob, resetFields } = useSubscribeToJob();
  const { onSubmitGetStandardDocs: onSubmitGetDocs } = useGetIndividualDocs();
  const newStandardDocuments = useSelector(documentSelectors.newStandardDocs);
  const stdDocsLength = newStandardDocuments.length;

  const docs = useSelector(documentSelectors.standardDocs);
  const uid = id ?? caseId;
  const firstName = useSelector(individualSelectors.selectFirstName);
  const lastName = useSelector(individualSelectors.selectLastName);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [showExtractDialog, setShowExtractDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    onSubmitAddPassExtractInfo,
    onSubmitAddVisaExtractInfo,
    onSubmitAddI94ExtractInfo,
    onSubmitAddResumeExtractInfo,
    // extractedData,
    isLoadingAddPassExtractInfo,
    isLoadingAddVisaExtractInfo,
    isLoadingAddI94ExtractInfo,
    isLoadingAddResumeExtractInfo,
    isExtractadDataNotFound,
    onSubmitAddEmptyData,
    setExtractedData
  } = useAddExtractInfo(uid);

  const extractedData = useSelector(documentSelectors.extractedData);

  const { isLoadingDeleteExtractedInfo, onDeleteAllExtractedInfo } =
    useDeleteExtractedInfo(uid);

  const isShouldUpdateExtraData =
    !isLoadingAddPassExtractInfo &&
    !isLoadingAddVisaExtractInfo &&
    !isLoadingAddI94ExtractInfo &&
    !isLoadingAddResumeExtractInfo &&
    !isLoadingDeleteExtractedInfo;

  type ExtractedDocument = {
    [key: string]: boolean;
  };

  const standardDocJob = useSelector((state: ExtractionReducer) =>
    extractionSelectors.selectJobById(state, "Standard Documents")
  );

  const passportDoc = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "passport")
  );

  const resumeDoc = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "resume")
  );

  const visaDoc = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "visa")
  );

  const i94Doc = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "i-94")
  );

  useEffect(() => {
    onSubmitGetDocs(uid!)
      .then((newdocs) => {
        console.log("docs");
      })
      .catch((error) => {
        console.error("Error fetching documents:", error);
      });
  }, [uid]);

  const { onSubmitExtractData: onSubmitExtractPassportInfo } = useExtractData(
    documentsTypes.passport
  );

  const { onSubmitExtractData: onSubmitExtractResumeInfo } = useExtractData(
    documentsTypes.resume
  );

  const { onSubmitExtractData: onSubmitExtractVisaInfo } = useExtractData(
    documentsTypes.visa
  );

  const { onSubmitExtractData: onSubmitExtractI94Info } = useExtractData(
    documentsTypes.i94
  );

  useEffect(() => {
    if (isExtractadDataNotFound) {
      onSubmitAddEmptyData();
    }
  }, [isExtractadDataNotFound]);

  const statusMsg = useMemo(() => {
    const state = standardDocJob?.status?.status as EXTRACTIONSTATES;

    if (!state) {
      return ExtractionStatesDescription.NotStarted;
    }

    return ExtractionStatesDescription[state];
  }, [standardDocJob]);

  const handleExtractPassportInfo = async () => {
    if (!passportDoc?.autoOCRText) return;

    const data = await onSubmitExtractPassportInfo(passportDoc.autoOCRText);

    if (data) {
      const passportData: ExtractedPassport = {
        passportFirstName: data.passport_first_name || "",
        passportMiddleName: data.passport_middle_name || "",
        passportLastName: data.passport_last_name || "",
        passportDoB: data.passport_doB || "",
        passportNumber: data.passport_number || "",
        passportDateOfIssue: data.passport_date_of_issue || "",
        passportExpirationDate: data.passport_expiration_date || "",
        passportCountry: data.passport_country || ""
      };
      await onSubmitAddPassExtractInfo({
        firstName,
        lastName,
        passport: formatPassport(passportData)
      });
    }
  };

  const handleExtractResumeInfo = async () => {
    if (!resumeDoc?.autoOCRText) return;
    const data = await onSubmitExtractResumeInfo(resumeDoc.autoOCRText);

    if (data) {
      const resumeData = {
        resumeFirstName: data.resume_first_name || "",
        resumeMiddleName: data.resume_middle_name || "",
        resumeLastName: data.resume_last_name || "",
        resumeEmailAddress: data.resume_email_address || "",
        resumeLastRole: data.resume_last_role || "",
        resumeLastEmployer: data.resume_last_employer || "",
        resumeLastRoleStartDate: data.resume_last_role_start_date || "",
        resumeLastRoleEndDate: data.resume_last_role_end_date || "",
        resumeFirstRoleBeforeLast: data.resume_first_role_before_last || "",
        resumeFirstEmployerBeforeLast:
          data.resume_first_employer_before_last || "",
        resumeFirstBeforeLastRoleStartDate:
          data.resume_first_before_last_role_start_date || "",
        resumeFirstBeforeLastRoleEndDate:
          data.resume_first_before_last_role_end_date || "",
        resumeSecondRoleBeforeLast: data.resume_second_role_before_last || "",
        resumeSecondEmployerBeforeLast:
          data.resume_second_employer_before_last || "",
        resumeSecondBeforeLastRoleStartDate:
          data.resume_second_before_last_role_start_date || "",
        resumeSecondBeforeLastRoleEndDate:
          data.resume_second_before_last_role_end_date || "",
        resumeLastEducationInstitution:
          data.resume_last_education_institution || "",
        resumeLastEducationDegree: data.resume_last_education_degree || "",
        resumeLastEducationDateObtained:
          data.resume_last_education_date_obtained || "",
        resumeFirstBeforeLastEducationInstitution:
          data.resume_first_before_last_education_institution || "",
        resumeFirstBeforeLastEducationDegree:
          data.resume_first_before_last_education_degree || "",
        resumeFirstBeforeLastEducationDateObtained:
          data.resume_first_before_last_education_date_obtained || "",
        resumeSecondBeforeLastEducationInstitution:
          data.resume_second_before_last_education_institution || "",
        resumeSecondBeforeLastEducationDegree:
          data.resume_second_before_last_education_degree || "",
        resumeSecondBeforeLastEducationDateObtained:
          data.resume_second_before_last_education_date_obtained || ""
      };

      await onSubmitAddResumeExtractInfo({
        firstName,
        lastName,
        resume: resumeData
      });
    }
  };

  const handleExtractVisaInfo = async () => {
    if (!visaDoc?.autoOCRText) return;

    const data = await onSubmitExtractVisaInfo(visaDoc.autoOCRText);

    if (data) {
      const visaData = {
        visaFirstName: data.visa_first_name || "",
        visaMiddleName: data.visa_middle_name || "",
        visaLastName: data.visa_last_name || "",
        visaPassportNumber: data.passport_number || "",
        visaControlNumber: data.visa_control_number || "",
        visaNumber: data.visa_number || "",
        visaType: data.visa_type || "",
        visaIssueDate: data.visa_issue_date || "",
        visaExpirationDate: data.visa_expiration_date || ""
      };

      await onSubmitAddVisaExtractInfo({
        firstName,
        lastName,
        visa: visaData
      });
    }
  };

  const handleExtractI94Info = async () => {
    if (!i94Doc?.autoOCRText) return;
    const data = await onSubmitExtractI94Info(i94Doc.autoOCRText);

    if (data) {
      const i94Data = {
        authorizedStayExpirationDate:
          data.i94_authorized_stay_expiration_date || "",
        classOfAdmission: data.i94_class_of_admission || "",
        recordNumber: data.i94_record_number || "",
        mostRecentDateOfEntry: data.i94_most_recent_date_of_entry || ""
      };

      await onSubmitAddI94ExtractInfo({
        firstName,
        lastName,
        i94: i94Data
      });
    }
  };

  const handleSummarizeResume = () => {
    return new Promise<void>((resolve, reject) => {
      try {
        const resumeIndex = newStandardDocuments.findIndex((doc) =>
          doc?.documentTitle?.toLowerCase().includes("resume")
        );

        if (resumeIndex !== -1) {
          const resumeDocument = newStandardDocuments[resumeIndex];

          const fullPath = `documents/${uid}/docs/${resumeDocument.id}`;
          console.log("handleSummarizeResume test: fullPath =", fullPath);
          summarizeResumeWithUpdate(uid!, fullPath);
          resolve();
        } else {
          // forceCompleteJob("Standard Documents");
          resolve();
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const onExtractConfirmation = async () => {
    console.log("extract submit test: Extract confirmation started");
    setShowExtractDialog(false);

    const job: ExtractionJob = {
      id: "Standard Documents",
      type: "Standard Documents Extraction",
      jobRef: doc(
        db,
        DATABASE.ACTIVE_JOBS,
        `${uid}`,
        "jobs",
        "Standard Documents"
      ),
      docRef: doc(db, DATABASE.EXTRACTED, `${uid}`),
      toastTitle: "Job Completed",
      toastDesc: "The extraction job has been completed.",
      docName: "Standard Documents",
      status: { status: EXTRACTIONSTATES.Pending },
      customCalls: [
        handleExtractPassportInfo,
        handleExtractResumeInfo,
        handleExtractVisaInfo,
        handleExtractI94Info,
        handleSummarizeResume
      ].filter((call) => call !== undefined && call !== null)
    };

    await initiateJob(job);
  };

  const handleExtract = async () => {
    if (standardDocJob?.status?.status === EXTRACTIONSTATES.Completed) {
      setShowExtractDialog(true);
    } else await onExtractConfirmation();
  };

  const {
    isOpen: extractionDeleteOpen,
    onOpen: onExtractionDelete,
    onClose: onExtractionDeleteClose
  } = useDisclosure();

  const buttonCancelRef = useRef<HTMLButtonElement | null>(null);
  const uploadDialogRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (buttonCancelRef) {
      uploadDialogRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }, [isLoadingDoc]);

  const handleDeleteExtracted = async () => {
    try {
      await onDeleteAllExtractedInfo();
      setExtractedData(null);
      await resetFields(standardDocJob, EXTRACTIONSTATES.NotStarted);
      toast({
        title: "Information deleted",
        description: "Information deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right"
      });
      onExtractionDeleteClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box>
      {/* standard-documents table */}
      <StandardDocumentsTable />
      <Divider my={2} opacity={0} />

      <SpecialHeading
        title="Extractions from standard documents"
        withInfo={
          <DocumentsInfoModal title="Extractions from standard documents" />
        }
      />

      <ExtractionCard
        type="documents/standard"
        id={id}
        extractedData={extractedData}
        onExtractionDelete={onExtractionDelete}
        statusMsg={statusMsg}
      />
      <Flex justifyContent="center">
        <CustomButton
          type="ai"
          title="Extract"
          isLoading={loading || isLoadingDeleteExtractedInfo}
          onClick={handleExtract}
        />
      </Flex>

      {/* Modals */}
      <DeleteEvidenceExtractionDialog
        isOpen={extractionDeleteOpen}
        onClose={onExtractionDeleteClose}
        onConfirm={handleDeleteExtracted}
      />
      <AlreadyExtractedDialog
        isOpen={showExtractDialog}
        onConfirm={onExtractConfirmation}
        type="standard"
        onClose={() => setShowExtractDialog(false)}
      />
      {/* Modals */}
    </Box>
  );
};
