import {
  Box,
  BoxProps,
  Divider,
  Flex,
  Heading,
  IconButton,
  Indicator,
  TabIndicator,
  Text
} from "@chakra-ui/react";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CustomBackButton from "../common/CustomBackButton";

interface Props extends BoxProps {
  title: string;
  backNav?: boolean;
  asTab?: boolean;
  withInfo?: React.ReactNode;
}

export const SpecialHeading = ({
  title,
  backNav = false,
  withInfo,
  ...rest
}: Props) => {
  return (
    <Box width="100%" {...rest} textAlign="left">
      <Heading
        fontFamily="Inter"
        color="text.display.light"
        size="md"
        textAlign="start"
        my={4}
        {...rest}
      >
        <Text className="relative">
          {title === "" ? null : title} {withInfo}
        </Text>
      </Heading>
    </Box>
  );
};

/** a wrapper for more consistent panel layouts
 *  @param {string} title : heading title
 */

export const PanelLayout = ({
  children,
  title,
  asTab,
  withInfo,
  ...rest
}: Props) => {
  const excludedTitles = ["Expert letter questionnaires", "Drafts"];
  return (
    <Box
      className="!w-[100%] bg-white p-4 border"
      borderTopRadius={12}
      style={{ boxShadow: "0px 10px 50px rgba(0, 0, 0, 0.1)" }}
    >
      {!excludedTitles.includes(title) && (
        <SpecialHeading title={title} withInfo={withInfo} {...rest} />
      )}
      <Box className="flex flex-col">{children}</Box>
    </Box>
  );
};
