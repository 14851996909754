import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CollectionReference,
  DocumentData,
  addDoc,
  collection
} from "firebase/firestore";
import { ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../../api/firebaseApi";
import { filterDocumentsBySupport } from "../../helpers/file_helpers";
import { DataDocs } from "../../types/tables-data";
import { addSubDocument } from "../documents/documentSlice";
import { generateDocumentUrls } from "../documents/documentsThunk";
import { VisaDocumentType } from "../documents/types";
import { RootState } from "../store";
import { setIsLoading, setUploadProgress } from "./fileUploadSlice";

export type UploadFilesProps = {
  path: string;
  uid: string;
  mainDocumentId?: string;
  type?: VisaDocumentType;
};

const getUserDocRef = (
  uid: string,
  mainDocumentId: string | undefined,
  type: VisaDocumentType
): CollectionReference<DocumentData> | undefined => {
  const isSub = mainDocumentId !== undefined;
  if (isSub) {
    switch (type) {
      case VisaDocumentType.Standard:
        return collection(
          db,
          `documents/${uid}/docs/${mainDocumentId}/sub_documents`
        );
      case VisaDocumentType.Evidence:
        return collection(
          db,
          `documents/${uid}/evidence_docs/${mainDocumentId}/docs`
        );
      case VisaDocumentType.SignedExpertLetters:
        return collection(
          db,
          `documents/${uid}/signed_expert_letters/${mainDocumentId}/docs`
        );
      default:
        throw new Error(`Unsupported document type: ${type}`);
    }
  } else {
    switch (type) {
      case VisaDocumentType.Standard:
        return collection(db, `documents/${uid}/docs`);
      case VisaDocumentType.Evidence:
        return collection(db, `documents/${uid}/evidence_docs`);
      case VisaDocumentType.SignedExpertLetters:
        return collection(db, `documents/${uid}/signed_expert_letters`);
      default:
        throw new Error(`Unsupported document type: ${type}`);
    }
  }
};

const createDocumentData = (fileRef: any, isSub: boolean): DataDocs => ({
  filePath: fileRef.fullPath,
  docNames: fileRef.name,
  documentTitle: !isSub ? "" : fileRef.name,
  uploadBy: "",
  type: "",
  description: "",
  uploadDate: Date.now(),
  isDeleted: false,
});

export const uploadFiles = createAsyncThunk(
  "fileUpload/uploadFiles",
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const { files, uploadConfiguration } = state.fileUpload;
    const { path, uid, mainDocumentId, documentType } = uploadConfiguration;
    const filteredFiles = filterDocumentsBySupport(files, true);

    try {
      const currentProgress = new Map<string, number>();
      const uploadPromises = filteredFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const fileRef = ref(storage, `${path}/${file.name}`);
          const uploadTask = uploadBytesResumable(fileRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              currentProgress.set(file.name, progress);
              const progressObject = Object.fromEntries(currentProgress);
              dispatch(setUploadProgress(progressObject));
            },
            (error) => reject(error),
            async () => {
              const userDocRef = getUserDocRef(
                uid,
                mainDocumentId,
                documentType
              );
              const document = createDocumentData(
                fileRef,
                mainDocumentId !== undefined
              );

              if (userDocRef) {
                const addedDocument = await addDoc(userDocRef, document);
                const documentWithId: DataDocs = {
                  ...document,
                  docRef: addedDocument.path,
                  id: addedDocument.id,
                  isDeleted: false
                };

                if (mainDocumentId !== undefined) {
                  dispatch(
                    addSubDocument({
                      data: { id: mainDocumentId, document: documentWithId },
                      type: documentType
                    })
                  );
                }

                // Resolves the Promise with an object containing:
                // 1. status: true, indicating a successful upload.
                // 2. document: The newly added document with its Firestore ID (documentWithId).
                // 3. mainDocumentId: The main document ID or an empty string if not a sub-document.
                // This resolved value is then used in the next promise chain to generate document URLs.

                resolve({
                  status: true,
                  document: documentWithId,
                  mainDocumentId: mainDocumentId ?? "",
                  type: documentType
                });
              }
            }
          );
        });
      });

      await Promise.all(uploadPromises).then((uploadedDocuments) => {
        // After all file upload promises are resolved, this block runs.

        uploadedDocuments.forEach(({ document, mainDocumentId, type }: any) => {
          // Dispatch an action to generate URLs for each uploaded document.
          // The documents array contains the file path and ID of the uploaded document
          // and the main document ID is passed if applicable.

          dispatch(
            generateDocumentUrls({
              documents: [{ filePath: document.filePath, id: document.id }],
              type,
              mainDocId: mainDocumentId
            })
          );
        });
        dispatch(setIsLoading(false));
      });

      return {};
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
