import { Button, Flex, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useUserRoleAndSubscription } from "../../../hooks/useUserRoleAndSubscription";
import { DocumentReducer } from "../../../redux/documents/documentSlice";
import { documentSelectors } from "../../../redux/documents/selectors";
import { EXTRACTIONSTATES } from "../../../redux/extraction-jobs/extractionJobsSlice";
import { DataDocs } from "../../../types/tables-data";
import { CustomBox } from "../../common/CustomBox";
import { CustomButton } from "../../common/CustomButton";
import { SpecialHeading } from "../PanelLayout";
import { ExpertLettersList } from "./ExpertLettersList";
import { DocumentsInfoModal } from "../../common/DocumentsInfoModal";

export const ExpertLetterDraftsSection = () => {
  const navigate = useNavigate();
  const { id, visaType } = useParams();
  const { isIndividual } = useUserRoleAndSubscription();

  const candidateResume = useSelector((state: DocumentReducer) =>
    documentSelectors.selectDocumentByType(state, "resume" ?? "")
  );

  // Input requirement
  const [missingRequirement, setMissingRequirement] = useState<
    string | undefined
  >(undefined);
  const [requirementMsg, setRequirementMsg] = useState<string | undefined>(
    undefined
  );

  const onGenerate = () => {
    navigate(
      `/individual/${id}/${visaType}/drafts/expert-letters/expert-letter-inputs/`
    );
  };

  const isGenerateDisabled = useMemo(() => {
    const checkIfRequirementsExist = (
      foundDocument: DataDocs | undefined,
      requirement: string
    ) => {
      // Inexisting dependency
      if (!foundDocument) {
        setMissingRequirement(requirement);
        setRequirementMsg(
          "To generate expert letters, please upload the necessary document(s):"
        );
        return true;
      }

      // Processing dependency
      if (foundDocument?.status?.status !== EXTRACTIONSTATES.Completed) {
        setMissingRequirement(requirement);
        setRequirementMsg(
          "Dependency document is processing. Please wait until it's complete:"
        );
        return true;
      }
      return false;
    };

    return checkIfRequirementsExist(candidateResume, "Candidate resume");
  }, [candidateResume]);

  return (
    <Flex flexDirection="column">
      <SpecialHeading
        title="Expert Letters"
        withInfo={<DocumentsInfoModal title="Expert Letters" />}
      />
      {/* TODO: refactor link depending on missing requirement type */}
      {isGenerateDisabled && missingRequirement && (
        <CustomBox type="info">
          <Flex flexDirection="column">
            <Text>{requirementMsg}</Text>{" "}
            <Flex p={4} columnGap={2} alignItems="center">
              <li>
                <Text as="span" fontWeight="bold">
                  {missingRequirement}
                </Text>
                {missingRequirement.includes("resume") && !candidateResume && (
                  <Button
                    m={2}
                    variant="secondaryOutline"
                    size="sm"
                    onClick={() => {
                      navigate("documents/standard-documents");
                    }}
                  >
                    Click here to upload
                  </Button>
                )}
              </li>
            </Flex>
          </Flex>
        </CustomBox>
      )}

      {/* Expert Letters List */}
      <ExpertLettersList />
      <Flex mt={4} justifyContent="center">
        <CustomButton
          type="ai"
          title="Add Expert"
          isPremium
          isDisabled={!candidateResume}
          onClick={onGenerate}
        />
        {isIndividual && (
          <Button
            className="btn primary-btn"
            isDisabled={!candidateResume}
            onClick={onGenerate}
          >
            Add Expert
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
